<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ProjectOverlay></ProjectOverlay>

	2019-06-08	init

-->

<template>
	<div class="ProjectOverlay" :class="elmClasses" @transitionend="onTransitionEnd" @click="onClickElm">

		<div class="ProjectOverlay__blurBackground" :class="{'blurBackground' : projectType === 'video'}"></div>

		<div class="ProjectOverlay__header hSpace hSpace--app" :class="{'ProjectOverlay__header--isVisible' : isHeaderVisible}">
			<div class="ProjectOverlay__headerInner">
				<a class="noSelect" @click="doClose()">Close</a>
			</div>
		</div>
		<div class="ProjectOverlay__body hasOverflow hasOverflow--hideScrollbars vSpace vSpace--app hSpace hSpace--app" :class="{'ProjectOverlay__body--isVisible' : isBodyVisible}">
			<template v-if="projectType === 'video'">

				<!-- key für das LayoutGrid wird benötigt, da VideoJs nicht wirklich reaktiv ist. -->

				<!--
				<transition
					name="component-fade"
					mode="out-in"
					v-on:before-leave="beforeLeave"
					v-on:leave="leave"
					v-on:after-leave="afterLeave"

					v-on:before-enter="beforeEnter"
					v-on:enter="enter"
					v-on:after-enter="afterEnter"

					appear
				>
				-->

					<LayoutGrid
						:gap="16"
						:key="$route.fullPath"
						:rowHeight="app.isMqBelowDt ? 'variable' : 'variable'">

						<!-- video -->
						<div class="GridCell"
							:style="
								app.isMqBelowDt ?
								'grid-column: span 12; grid-row: span 1' :
								'grid-column: span 8; grid-row: span 1'
							">
							<!--
							<mark>{{'x' + _.get( post, 'id')}}</mark>
							-->
							<VideoJs
								:posterImage="_.get( post, 'acf.teaserImage' )"
								:aspectRatio="projectVideoAspectRatio"
								:sources="projectVideoSources"
							></VideoJs>
						</div>
						<!--
						-->

						<!-- text -->
						<GridCell
							:columns="app.isMqBelowDt ? 12 : 4"
							:rows="1"
							:cellAspectRatio="'auto'"
							:contentAspectRatio="app.isMqBelowDt ? 'by text' : 'auto'"

							:text="boxText"
							:pageIsVisible="pageIsVisible"
						></GridCell>

						<!-- next project -->
						<template v-if="nextProjectPost">
							<GridCell class="noPointerEvents" v-if="!app.isMqBelowDt"
								:columns="4"
								:cellAspectRatio="'16:9'"
								:pageIsVisible="pageIsVisible"
							></GridCell>
							<GridCell class="placeholderCell"
								:columns="app.isMqBelowDt ? 6 : 4"
								:cellAspectRatio="'16:9'"
								:title="_.get(nextProjectPost, 'acf.title') ? _.get(nextProjectPost, 'acf.title') : _.get(nextProjectPost, 'title.rendered')"
								:image="_.get(nextProjectPost, 'acf.teaserImage')"
								:clip="_.get(nextProjectPost, 'acf.clip')"
								:pageIsVisible="pageIsVisible"
								:autoplayClip="true"
								@click.native="gotoNextProject( $event, nextProjectPost )"
							></GridCell>
							<GridCell
								:columns="app.isMqBelowDt ? 6 : 4"
								:cellAspectRatio="'16:9'"
								:text="'<a>next project</a>'"
								:projectSlug="_.get( nextProjectPost, 'slug')"
								:pageIsVisible="pageIsVisible"
								@click.native="gotoNextProject( $event, nextProjectPost )"
							></GridCell>
						</template>

					</LayoutGrid>

				<!--
				</transition>
				-->

			</template>
			<template v-if="projectType === 'photo'">

				<!-- smartphones -->
				<template v-if="app.isMqBelowDt">

					<!-- header with hero media and text -->
					<TextBox
						:text="boxText"
					></TextBox>
					<div class="gridRowGap"></div>
					<MediaBox
						:image="_.get(post, 'acf.teaserImage')"
						:pageIsVisible="pageIsVisible"
					></MediaBox>
					<div class="gridRowGap"></div>

					<!-- manuell grid items -->
					<template v-for="(cell, i) in _.get( post, 'acf.grid.gridItems')">
						<template v-if="cell.acf_fc_layout != 'box--spacer'">

							<MediaBox
								:key="'i'+i"
								:image="app.getCellImage( cell, i )"
								:clip="app.getCellClip( cell )"
								:title="app.getCellTitle( cell )"

								:projectSlug="app.getCellProjectSlug( cell )"
								:playClipOnHover="true"
								:autoplayClip="app.getCellAutoplayClip( cell, i )"
								:showVideoControls="false"
								:isVideoMuted="true"
								:pageIsVisible="pageIsVisible"
							></MediaBox>

							<div class="gridRowGap" :key="'iii'+i"></div>

						</template>
					</template>

				</template>

				<!-- desktop -->
				<template v-else>

					<!-- header with hero media and text -->
					<LayoutGrid
						:gap="16"
						:rowHeight="'variable'">
						<!-- querformat teaser: 8 columns image -->
						<template v-if="app.isLandscapeMedia( _.get(post, 'acf.teaserImage') )">
							<!-- image -->
							<GridCell
								:columns="8"
								:rows="1"
								:cellAspectRatio="app.getAspectRatioByMedia( _.get(post, 'acf.teaserImage') )"

								:image="_.get(post, 'acf.teaserImage')"
								:pageIsVisible="pageIsVisible"
								@click.native="onClickGridCell( $event, false, -1, post )"
							></GridCell>
						</template>
						<!-- hochformat teaser: 4 columns spacer + 4 columns image -->
						<template v-else>
							<!-- spacer -->
							<GridCell
								:columns="4"
								:rows="1"
								:cellAspectRatio="'auto'"
								:pageIsVisible="pageIsVisible"
							></GridCell>
							<!-- image -->
							<GridCell
								:columns="4"
								:rows="1"
								:cellAspectRatio="app.getAspectRatioByMedia( _.get(post, 'acf.teaserImage') )"

								:image="_.get(post, 'acf.teaserImage')"
								:pageIsVisible="pageIsVisible"
								@click.native="onClickGridCell( $event, false, -1, post )"
							></GridCell>

						</template>
						<!-- text -->
						<GridCell
							:columns="4"
							:rows="1"
							:cellAspectRatio="'auto'"

							:text="boxText"
							:pageIsVisible="pageIsVisible"
						></GridCell>
					</LayoutGrid>
					<!-- manuell grid items -->
					<LayoutGrid v-if="_.get(post, 'acf.grid')"
						:gap="16"
						:rowHeight="_.get(post, 'acf.grid.rowHeight')">
						<GridCell v-for="(cell, i) in _.get(post, 'acf.grid.gridItems')" :key="i"
							:columns="cell.columns"
							:rows="cell.rows"
							:cellAspectRatio="app.getCellAspectRatio( cell )"
							:contentAspectRatio="app.getCellContentAspectRatio( cell )"

							:projectSlug="app.getCellProjectSlug( cell )"
							:text="app.getCellText( cell )"
							:textVerticalAlign="app.getCellTextVerticalAlign( cell )"
							:title="app.getCellTitle( cell )"
							:image="app.getCellImage( cell )"
							:clip="app.getCellClip( cell )"
							:autoplayClip="false"
							@click.native="onClickGridCell( $event, cell, i, post )"
							:pageIsVisible="pageIsVisible"
						></GridCell>
					</LayoutGrid>

				</template>
			</template>

			<!--
			<pre name="post">{{post}}</pre>
			<br/><br/><br/><br/><br/><br/>
			-->
		</div>

	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'

	import { EventBus } from '@/event-bus.js'
	import LayoutGrid from './LayoutGrid.vue'
	import GridCell from './GridCell.vue'
	import MediaBox from '../components/MediaBox.vue'
	import TextBox from '../components/TextBox.vue'
	import VideoJs from '../components/VideoJs.vue'

	export default {
		name: 'ProjectOverlay',
		components: {
			LayoutGrid,
			GridCell,
			MediaBox,
			TextBox,
			VideoJs,
		},
		mixins: [],
		props: {},
		data(){
			return {
				post                 : undefined, // wird via watch $route gesetzt
				nextProjectPost      : undefined,  // wird via setNextProject() gesetzt
				isAnimating          : false,
				removeContentTimeout : undefined,
				keyUpListener        : undefined,

				isVisible       : false,
				isHeaderVisible : false,
				isBodyVisible   : false,
			}
		},
		watch: {
			'$route.query.project': {
				handler: function( to, from, doLog = false ){

					if( doLog ){
						console.log(this.$options.name, '• watch $route.query.project', from, to)
					}

					clearTimeout( this.removeContentTimeout )
					this.isAnimating = true

					// show overlay < url has project slug
					if( to ){
						this.post = this.app.getPostBySlug( this.$route.query.project )
						this.setNextProject()

						this.$nextTick(()=>{
							this.isVisible = true
						})
					}
					// close overlay, delayed post disappear for nicer ux
					else{
						this.isVisible = false

						this.removeContentTimeout = setTimeout(()=>{
							this.post = undefined
						}, 1000)
					}
				},
				immediate: true,
				deep: false,
			},
			'app.posts': { // onload muss der post nach dem fetch gesetzt werden
				handler: function( to, from ){
					//console.log(this.$options.name, '• watch app.posts', from, to)

					this.post = this.app.getPostBySlug( this.$route.query.project )
					this.isAnimating = false
				},
				immediate: false,
				deep: false,
			},
			isVisible: {
				handler: function( to, from ){
					if( to ){
						EventBus.$emit('ProjectOverlay is open')

						// delay fade in to let the browser render
						// this solves an jumping-content-issue in safari
						setTimeout( ()=>{
							this.$nextTick( ()=>{
								this.isHeaderVisible = true
								this.isBodyVisible   = true
							})
						}, 250 )
					}
					else{
						EventBus.$emit('ProjectOverlay is closed')

						setTimeout( ()=>{
							this.$nextTick( ()=>{
								this.isHeaderVisible = false
								this.isBodyVisible   = false
							})
						}, 75 )
					}
				},
				immediate: true,
				deep: false,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--type-' + this.projectType )
				if( this.isVisible ) classes.push( this.$options.name + '--isVisible')
				//if( this.isAnimating ) classes.push( this.$options.name + '--isAnimating')

				return classes
			},

			projectType(){
				//return 'video'
				return this._.get( this.post, 'acf.type' )
			},
			projectVideoAspectRatio(){
				let height = this._.get( this.post, 'acf.clip.height', false )
				let width  = this._.get( this.post, 'acf.clip.width', false )
				let ratio  = height && width ? width + ':' + height : false
 				return ratio
			},
			projectVideoSources(){
				let vimeoObj = this._.get( this.post, 'acf.vimeo', false )
				let sources  = []

				//console.log('this.post:', this.post)
				//console.log('vimeoObj:', vimeoObj)

				if( vimeoObj ){

					if( vimeoObj.high_def_1080_mp4_1920x1080 ){
						sources.push({
							type: "video/mp4",
							width: 1920,
							src: vimeoObj.high_def_1080_mp4_1920x1080
						})
					}
					if( vimeoObj.high_def_mp4_1280x720 ){
						sources.push({
							type: "video/mp4",
							width: 1280,
							src: vimeoObj.high_def_mp4_1280x720
						})
					}
					if( vimeoObj.standard_def_mp4_960x540 ){
						sources.push({
							type: "video/mp4",
							width: 960,
							src: vimeoObj.standard_def_mp4_960x540
						})
					}
					if( vimeoObj.standard_def_mp4_640x360 ){
						sources.push({
							type: "video/mp4",
							width: 640,
							src: vimeoObj.standard_def_mp4_640x360
						})
					}
				}

				return sources.length ? sources : false
			},

			title(){
				const postTitle = this._.get( this.post, 'title.rendered' )
				const acfTitle = this._.get( this.post, 'acf.title' )

				return acfTitle ? acfTitle : postTitle // use acf title with post title as fallback
			},
			boxText(){
				return [
					'<h1  class="headline">' + this.title + '</h1>',
					'<div class="columns">',
					'	<div class="column">' + this._.get( this.post, 'acf.textFirstColumn', '' ) + '</div>',
					'	<div class="column">' + this._.get( this.post, 'acf.textSecondColumn', '' ) + '</div>',
					'</div>',
					//'<mark>'+this._.get( this.post, 'id' )+'</mark>',
				].join('')
			},
			pageIsVisible(){
				return true
			},
			imagesForLightbox(){ // collect all images that should be shown in the ImageLightbox
				const post       = this.post
				const photoBoxes = this._.get( post, 'acf.grid.gridItems', [] ).filter( (o)=>{ return o.acf_fc_layout === 'box--photo' })
				const images     = []

				if( post ){
					images.push( this._.get( post, 'acf.teaserImage', false ) )

					photoBoxes.forEach( box=>{
						const image = box.image

						if( image ) images.push( image )
					})

					//console.log('images:', images)
				}

				return images
			},
		},
		methods: {
			setNextProject( doLog = false ){
				const viewName      = this.$route.name
				const projectPost   = this.post || {}
				const projectId     = projectPost.id
				let    overviewPost = undefined
				let   nextProjectId = undefined

				// Normalfall: Es werden die Boxen der Directors-Page genommen um das "next project" zu bestimmen
				overviewPost = this.app.getPostById( this._.get( projectPost, 'acf.artist' ) )

				// Sonderfall: für den HomeView werden die Boxen auf der Homeseite verwendet
				if( viewName == 'HomeView' ) overviewPost = this.app.getPostBySlug('work')

				// Bestimmen des "next project":
				// - get all boxes
				// - nur die boxes die projects sind
				// - davon nur alle die video projects sind
				const overviewPostBoxes = this._.get( overviewPost, 'acf.grid.gridItems', [] )
				const overviewPostTeaserBoxes = this._.filter( overviewPostBoxes, { acf_fc_layout : 'box--project' } )
				const allVideoProjects = []
				overviewPostTeaserBoxes.forEach((box)=>{
					const _projectId = box.projectId
					const _projectPost = this.app.getPostById( _projectId )

					if( this._.get( _projectPost, 'acf.type' ) === 'video' ) allVideoProjects.push( _projectPost )
				})
				const currentBoxIndex = this._.findIndex( allVideoProjects, { id : projectId } )
				nextProjectId = this._.get( allVideoProjects[currentBoxIndex+1], 'id' )
				const firstProjectId = this._.get( allVideoProjects[0], 'id' )
				const nextProjectPost = this.app.getPostById( nextProjectId ) || this.app.getPostById( firstProjectId )

				// Debug log
				if( doLog ){
					console.group( this.$options.name, '• setNextProject()')
					console.log('projectId:', projectId)
					console.log('overviewPostTeaserBoxes:', overviewPostTeaserBoxes)
					console.log('allVideoProjects:', allVideoProjects)
					console.log('currentBoxIndex:', currentBoxIndex)
					console.groupEnd()
				}

				this.nextProjectPost = nextProjectPost ? nextProjectPost : false
			},
			doClose(){
				this.$router.push({ query: { project: undefined }})
			},
			onTransitionEnd(){
				this.isAnimating = false

				//console.log(this.$options.name, '• onTransitionEnd()')
			},
			onClickElm( e, doLog = false ){ // handles close overlay on background click
				const target = e.target
				const validClasses = ['blurBackground', 'LayoutGrid__inner']
				let hasValidCloseClass = false

				// walk the valid classes and check if target has at least one
				this._.forEach( validClasses, (validClass)=>{
					if( target.classList.contains( validClass ) ){
						hasValidCloseClass = true
					}
				})

				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClickElm()' )
					console.log('target:', target)
					console.log('hasValidCloseClass:', hasValidCloseClass)
					console.groupEnd()
				}

				if( hasValidCloseClass ){
					this.doClose()
				}
			},
			onClickGridCell( e, cell, cellIndex, post, doLog = false ){
				if( doLog ){
					console.group( this.$options.name, '• onClickGridCell( e, cell, cellIndex, post )' )
					console.log('cell:', cell)
					console.log('cellIndex:', cellIndex)
					console.log('post:', post)
					console.groupEnd()
				}

				EventBus.$emit('Open Lightbox', {
					startWith : cellIndex+1,
					images    : this.imagesForLightbox,
					//post      : post,
				})
			},
			getNextProjectLink( slug ){
				console.group( this.$options.name, ' • getNextProjectLink( slug )')
				console.log('slug:', slug)
				console.groupEnd()
			},
			gotoNextProject( e, nextProjectPost ){
				console.log(e)
				console.log(nextProjectPost)
				console.log(nextProjectPost)

				this.isBodyVisible = false

				setTimeout(()=>{
					this.$router.push('?project='+this._.get( nextProjectPost, 'slug' ) )
				}, 200)
				setTimeout(()=>{
					this.isBodyVisible = true
				}, 300)

				e.preventDefault()
			},

			beforeLeave( e, doLog = false ){
				const postId = this._.get( this.post, 'id' )

				if( doLog ){
					console.group( this.$options.name + ' • beforeLeave', postId)
					console.groupEnd()
				}
			},
			leave( e, doLog = false ){
				const postId = this._.get( this.post, 'id' )

				if( doLog ){
					console.group( this.$options.name + ' • leave', postId)
					console.groupEnd()
				}
			},
			afterLeave( e, doLog = false ){
				const postId = this._.get( this.post, 'id' )

				if( doLog ){
					console.group( this.$options.name + ' • afterLeave', postId)
					console.groupEnd()
				}
			},
			beforeEnter( e, doLog = false ){
				const postId = this._.get( this.post, 'id' )

				if( doLog ){
					console.group( this.$options.name + ' • beforeEnter', postId)
					console.groupEnd()
				}
			},
			enter( e, doLog = false ){
				const postId = this._.get( this.post, 'id' )

				if( doLog ){
					console.group( this.$options.name + ' • enter', postId)
					console.groupEnd()
				}
			},
			afterEnter( e, doLog = false ){
				const postId = this._.get( this.post, 'id' )

				if( doLog ){
					console.group( this.$options.name + ' • afterEnter', postId)
					console.groupEnd()
				}
			},
		},
		created(){
			this.keyUpListener = document.addEventListener('keyup', (e)=>{
				if( e.key === "Escape" && this.isVisible ) this.doClose()
			}, false)
		},
		mounted(){},
		destroyed(){
			document.removeEventListener('keyup', this.keyUpListener)
		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.ProjectOverlay {
		//background-color: lighten( black, 10 );
		//background-color: black;

		position: fixed;
		left: 0;
		height: 100vh; width: 100vw;
		//width: calc( 100vw - var(--scrollbarWidth) );
		display: flex;
		flex-direction: column;

		transition: top @transitions[projectOverlayDuration] ease;
		z-index: 20;
		top: 100vh;
		color: white;
		will-change: top;

		&--isVisible { top: 0; }
		&--isAnimating { pointer-events: none; }
		&--type-photo {
			color: @swatches[textBlack];

			&::before { // white bg
				position: absolute;
				top: 0; bottom: 0;
				left: @app[dtPaddingLeft];
				right: calc( @app[dtPaddingRight] + var(--scrollbarWidth) );
				background-color: @swatches[bgWhite];
				pointer-events: none;
				content: "";
			}
		}

		&__blurBackground {
			transition: all @transitions[projectOverlayDuration] ease;
			bottom: 0;
		}
		&__header {
			//background-color: fade( green, 35 );
			width: calc( 100vw - var(--scrollbarWidth) );
			@media @mediaQueries[dt] { height: @app[dtHeaderHeight]; }
			@media @mediaQueries[md] { height: @app[mdHeaderHeight]; }
			line-height: 1em;
			display: flex;
			flex-shrink: 0;
			z-index: 2;

			&Inner {
				flex-grow: 1;
				display: flex;

				@media @mediaQueries[md] { justify-content: flex-end;}
				@media @mediaQueries[dt] { justify-content: flex-start; }
			}

			a {
				display: flex;
				//flex-grow: 1;
				align-items: center;
				padding: 0 1rem; margin: 0 -1rem; // enlarge click-space

				color: inherit;
				&:hover { color: @swatches[textRed] };
			}
		}
		&--type-photo &__headerInner {
			padding: 0 0.35em;
		}
		&__body {
			//background-color: fade( blue, 35 );
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			//flex-grow: 1;
			//overflow-y: auto;
		}

		// hide MhImage on Vimeo-Player
		// TODO: muss wieder hin aber als poster
		//&--type-video .MhImage { opacity: 0; }
		&--type-video .MhVideo { pointer-events: auto; }

		@media @mediaQueries[md] {
			.GridCell.placeholderCell .MhImage__imageWrapper {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
			}
		}
	}
	.ProjectOverlay { // content fade in transitions
		&__header,
		&__body {
			transition: all 0.15s ease;
			opacity: 0;
		}

		&__header--isVisible,
		&__body--isVisible {
			opacity: 1;
		}
	}
	.ProjectOverlay { // transition between projects (e.g. after next project is clicked)
		--contentTransitionDuration : 500ms;
		--contentTransitionDuration : 2500ms;
		--contentTransitionDelay    :   0ms;

		//transition-duration: var(--contentTransitionDuration);
		//transition-property: opacity;
		//transition-timing-function: ease;
		//transition: opacity var(--contentTransitionDuration) ease;
		//opacity: 1;

		.component-fade-enter-active, .component-fade-leave-active {
			transition: opacity .3s ease;
		}
		.component-fade-enter, .component-fade-leave-to
		/* .component-fade-leave-active below version 2.1.8 */ {
			opacity: 0;
		}

		.fade-enter-active,
		.fade-leave-active {
			//transition-duration: var(--contentTransitionDuration);
			//transition-property: opacity;
			//transition-timing-function: ease;
			transition: opacity var(--contentTransitionDuration) ease;
		}
		.fade-enter-active {
			transition-delay: var(--contentTransitionDelay) !important;
		}
		.fade-enter,
		.fade-leave-active {
			//transform: translateX(-100%);
			//opacity: 0;
			transition-delay: var(--contentTransitionDelay) !important;
		}

	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
