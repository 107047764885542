/* eslint-disable */

/**
 *	2021-09-28
 *	Taken from https://raw.githubusercontent.com/BKWLD/vue-in-viewport-mixin/master/index.js
 *	And modified to get it working. I had to compile the index.coffee file ...
 */

// Mixin definition
var indexOf = [].indexOf,
	slice = [].slice;

export default {
	// Public interface
	props: {
		// Add listeners and check if in viewport immediately
		inViewportActive: {
			type: Boolean,
			default: true
		},
		// Only update once by default. The assumption is that it will be used for
		// one-time buildins
		inViewportOnce: {
			type: Boolean,
			default: false
		},
		// The IntersectionObserver root margin adds offsets to when the now and
		// fully get updated.
		XXXinViewportRootMargin: {
			type: Number | String,
			default: '0px 0px -1px 0px'
		},
		// Specify the IntersectionObserver root to use.
		inViewportRoot: {
			type: String | Function | Object,
			default: void 0
		},
		// The IntersectionObserver threshold defines the intersection ratios that
		// fire the observer callback
		inViewportThreshold: {
			type: Number | Array,
			default: function () {
				return [
					0,
					1 // Fire on enter/leave and fully enter/leave
				];
			}
		}
	},

	// Bindings that are used by the host component
	data: function () {
		return {
			inViewport: {
				// Public props
				now: null, // Is in viewport
				fully: null, // Is fully in viewport
				above: null, // Is partially or fully above the viewport
				below: null, // Is partially or fully below the viewport

				// Internal props
				listening: false,
				maxThreshold: 1
			},
			inViewportRootMargin: '0px 0px -1px 0px'
		};
	},
	// Lifecycle hooks
	mounted: function () {
		return this.$nextTick( this.inViewportInit );
	},
	destroyed: function () {
		return this.removeInViewportHandlers();
	},
	computed: {
		// Add the maxThreshold to the @inViewportThreshold prop so that the handler
		// is fired for elements that are taller than the viewport
		inViewportThresholdWithMax: function () {
			var ref, threshold;
			// Support number and array thresholds
			threshold = typeof this.inViewportThreshold === 'object' ? this.inViewportThreshold : [this.inViewportThreshold];
			// Add only if not already in the threshold list
			if ( ref = this.inViewport.maxThreshold, indexOf.call( threshold, ref ) >= 0 ) {
				return threshold;
			} else {
				return threshold.concat( this.inViewport.maxThreshold );
			}
		}
	},
	// Watch props and data
	watch: {
		// Add or remove event handlers handlers
		inViewportActive: function ( active ) {
			if ( active ) {
				return this.addInViewportHandlers();
			} else {
				return this.removeInViewportHandlers();
			}
		},
		// If any of the Observer options change, re-init.
		inViewportRootMargin: function () {
			return this.reInitInViewportMixin();
		},
		inViewportRoot: function () {
			return this.reInitInViewportMixin();
		},
		inViewportThresholdWithMax: function ( now, old ) {
			if ( now.toString() !== old.toString() ) {
				// In IE, this kept getting retriggered, so doing a manual comparison
				// of old and new before deciding whether to take action.
				return this.reInitInViewportMixin();
			}
		}
	},
	// Public API
	methods: {
		// Re-init
		reInitInViewportMixin: function () {
			this.removeInViewportHandlers();
			return this.inViewportInit();
		},
		// Instantiate
		inViewportInit: function () {
			if ( this.inViewportActive ) {
				return this.addInViewportHandlers();
			}
		},
		// Add listeners
		addInViewportHandlers: function () {
			// Don't add twice
			if ( this.inViewport.listening ) {
				return;
			}
			this.inViewport.listening = true;
			// Create IntersectionObserver instance
			this.inViewportObserver = new IntersectionObserver( this.updateInViewport, {
				root: ( function () {
					switch ( typeof this.inViewportRoot ) {
						case 'function':
							return this.inViewportRoot();
						case 'string':
							return document.querySelector( this.inViewportRoot );
						case 'object':
							return this.inViewportRoot; // Expects to be a DOMElement
						default:
							return void 0;
					}
				} ).call( this ),
				rootMargin: this.inViewportRootMargin,
				threshold: this.inViewportThresholdWithMax
			} );
			// Start listening
			return this.inViewportObserver.observe( this.$el );
		},
		// Remove listeners
		removeInViewportHandlers: function () {
			var ref;
			// Don't remove twice
			if ( !this.inViewport.listening ) {
				return;
			}
			this.inViewport.listening = false;
			// Destroy instance, which also removes listeners
			if ( ( ref = this.inViewportObserver ) != null ) {
				ref.disconnect();
			}
			return delete this.inViewportObserver;
		},
		// Handle state changes.  There should only ever be one entry and we're
		// destructuring the properties we care about since they have long names.
		updateInViewport: function ( arg ) {
			var arg, root, target;
			[
				{
					boundingClientRect: target,
					rootBounds: root
				}
			] = slice.call( arg, -1 );
			// Get the maximum threshold ratio, which is less than 1 when the
			// element is taller than the viewport. The height may be 0 when the
			// parent element is hidden.
			this.inViewport.maxThreshold = target.height > 0 ? Math.min( 1, root.height / target.height ) : 1;
			// Check if some part of the target is in the root box.  The isIntersecting
			// property from the IntersectionObserver was not used because it reports
			// the case where a box is immediately offscreen as intersecting, even
			// though no aprt of it is visible.
			this.inViewport.now = target.top <= root.bottom && target.bottom > root.top;
			// Calculate above and below.  The +1 on the bottom check co-incides with
			// the default root-margin which has a -1 on the bottom margin.
			this.inViewport.above = target.top < root.top;
			this.inViewport.below = target.bottom > root.bottom + 1;
			// Determine whether fully in viewport. The rules are different based on
			// whether the target is taller than the viewport.
			this.inViewport.fully = target.height > root.height ? target.top <= root.top && target.bottom >= root.bottom + 1 : !this.inViewport.above && !this.inViewport.below;
			if ( this.inViewportOnce && this.inViewport.now ) {
				// If set to update "once", remove listeners if in viewport
				return this.removeInViewportHandlers();
			}
		}
	}
};
