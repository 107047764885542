<!--

	Diese Componente ist ein simpler Wrapper für
	https://github.com/surmon-china/vue-video-player
	was wiederrum ein Wrapper für die https://videojs.com/ lib ist

	Props:
		aspectRatio 	String
		posterImage 	Object
		sources			Array

	Beispiel Code:
		<VideoJs
			:aspectRatio="'4:3'"
			:posterImage="teaserImage"
			:sources="[
				{ type: "video/mp4", url: "https://lowres.mp4", width: 512, }
				{ type: "video/mp4", url: "https://highres.mp4", width: 2512, }
			]"
		></VideoJs>

	2019-06-08	init

-->

<template>
	<div class="VideoJs" :class="elmClasses">
		<!--
		<mark>VideoJs</mark><br/>
		<pre name="aspectRatio">{{aspectRatio}}</pre>
		<pre name="sources">{{sources}}</pre>
		<pre name="posterImage">{{posterImage}}</pre>
		<hr/>
		-->
		<video-player    class="video-player-box"
						 v-if="aspectRatio && sources"
						 ref="videoPlayer"
						 :options="playerOptions"
						 :playsinline="true"
						 :controls="true"

						 @play="onPlayerPlay($event)"
						 @pause="onPlayerPause($event)"
						 @ended="onPlayerEnded($event)"
						 @waiting="onPlayerWaiting($event)"
						 @playing="onPlayerPlaying($event)"
						 @loadeddata="onPlayerLoadedData($event)"
						 @timeupdate="onPlayerTimeUpdate($event)"
						 @canplay="onPlayerCanPlay($event)"
						 @canplaythrough="onPlayerCanPlayThrough($event)"

						 @statechanged="onPlayerStateChanged($event)"
						 @ready="onPlayerReady($event)">
		</video-player>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import 'video.js/dist/video-js.css'
	import { videoPlayer } from 'vue-video-player'

	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'VideoJs',
		components: {
			videoPlayer,
		},
		mixins: [],
		props: {
			aspectRatio: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			posterImage: {
				type     : [Object, Boolean],
				default  : false,
				required : false,
			},
			sources: {
				type     : [Array, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {
				playerOptions: {
					// videojs options
					muted: false,
					controls: true,
					language: 'en',
					//playbackRates: [0.7, 1.0, 1.5, 2.0],
					aspectRatio: this.aspectRatio,
					sources: [{
						type: "video/mp4",
						//src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
						src: this._.get( this.sources[0], 'src' )
					}],
					//poster: "http://doity-dev:8080/wp-content/uploads/2019/05/dummy-1760x990-alpinist-1200x675.jpg",
					poster: this._.get( this.posterImage, 'sizes.large' ),
				}
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			player() {
				return this.$refs.videoPlayer.player
			},
		},
		methods: {
			onPlayerPlay( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerPlay()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerPause( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerPause()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerEnded( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerEnded()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerWaiting( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerWaiting()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerPlaying( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerPlaying()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerLoadedData( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerLoadedData()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerTimeUpdate( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerTimeUpdate()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerCanPlay( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerCanPlay()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerCanPlayThrough( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerCanPlayThrough()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerStateChanged( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerStateChanged()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
			onPlayerReady( event, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onPlayerReady()')
					console.log('event:', event)
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.VideoJs { // debug
	}
	.VideoJs { // layout
		.video-player { width: 100%; }
	}
	.VideoJs { // styling
		// show controls on start
		//:not(.vjs-has-started)
		.vjs-control-bar { display: flex; margin-bottom: 0.5em; }
		// hide big player button
		.vjs-big-play-button { display: none; }

		// progress tooltip
		.vjs-progress-control:hover .vjs-progress-holder {
			font-size: 1em;
		}

		.vjs-play-progress.vjs-slider-bar .vjs-time-tooltip {
			&:empty { display: none; }
			font-size: 1em !important;
		}

		// time on progressbar hover tooltip
		.vjs-mouse-display .vjs-time-tooltip {
			font-size: 1em !important;
		}

		// remove white glow
		.video-js .vjs-control:focus:before,
		.video-js .vjs-control:hover:before,
		.video-js .vjs-control:focus {
			text-shadow: none !important;
		}

		// whole bar
		.vjs-slider {
			//background-color: fade( white, 25 );
		}

		// hide loaded progress bar
		.video-js .vjs-load-progress { display: none; }
		// play loaded progress bar
		.video-js .vjs-play-progress {
			background-color: fade( white, 100 );

			&::before {
				transform: scale(1.3);

			}
		}
		.video-js .vjs-volume-level {
			&::before {
				transform: scale(1.3);

			}
		}

		.video-js .vjs-control-bar {
			background-color: transparent;

			.vjs-play-control { order: 1; }
			.vjs-volume-panel { order: 3; }
			.vjs-progress-control { order: 2; }
			.vjs-fullscreen-control { order: 4; }
		}

		// icons
		.vjs-play-control {
			//background-image: url(../assets/imgs/icon--play.svg);
			//background-size: contain;
			//background-repeat: no-repeat;
			//background-position: center center;
			//background-color: yellow;
		}
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
