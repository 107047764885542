import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VShowSlide from 'v-show-slide'
import VueMq from 'vue-mq'
import { EventBus } from '@/event-bus.js'

Vue.config.productionTip = false
// play beep sound and log errors
Vue.config.errorHandler = (err, vm, info) => {
	// err: error trace
	// vm: component in which error occured
	// info: Vue specific error information such as lifecycle hooks, events etc.

	// TODO: Perform any custom logic or log to server
	const componentName = vm.$options.name

	console.group('%cerrorHandler() •', 'color : red', componentName)
	console.error('err:', err)
	console.log('%cvm:', 'color : red', vm)
	console.log('%cinfo:', 'color : red', info)
	console.groupEnd()

	const a = new AudioContext() // browsers limit the number of concurrent audio contexts, so you better re-use'em

	function beep(vol, freq, duration) {
		const v = a.createOscillator()
		const u = a.createGain()
		v.connect(u)
		v.frequency.value = freq
		v.type = "square"
		u.connect(a.destination)
		u.gain.value = vol * 0.01
		v.start(a.currentTime)
		v.stop(a.currentTime + duration * 0.001)
	}

	beep(10, 320, 150)
}
Vue.config.XXXwarnHandler = (msg, vm, trace) => {
	const componentName = vm ? vm.$options.name : null

	console.group('%cwarnHandler() •', 'color : red', componentName)
	console.error('%cmsg:', 'color : red', msg)
	console.log('%ctrace:', 'color : red', trace)
	console.log('%cvm:', 'color : red', vm)
	console.groupEnd()

	const a = new AudioContext() // browsers limit the number of concurrent audio contexts, so you better re-use'em

	function beep(vol, freq, duration) {
		const v = a.createOscillator()
		const u = a.createGain()
		v.connect(u)
		v.frequency.value = freq
		v.type = "square"
		u.connect(a.destination)
		u.gain.value = vol * 0.01
		v.start(a.currentTime)
		v.stop(a.currentTime + duration * 0.001)
	}

	beep(15, 220, 150)
}

Vue.use(VueAxios, axios)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VShowSlide)
Vue.use(VueMq, {
	breakpoints: {
		xs : 350,
		sm : 750,
		md : 1023,
		dt : 1499,
		lg : 1799,
		xl : 1800,
	}
})

// Setup and mount root
new Vue({
	router,
	store,
	data() {
		return {}
	},
	watch: {},
	computed: {},
	methods: {
		getTranslatedValue( deValue ){
			if(!this.$children[0].acfOptions) return deValue

			const currentLang = this.getCurrentLang()
			const translations = this.$children[0].acfOptions.translations2
			const enValue = translations[deValue]

			if('en' == currentLang && enValue){
				return enValue
			}else{
				return deValue
			}
		},
		getPathFromUrl( url ){
			if( !url ) return

			return url.replace(/^.*\/\/[^\/]+/, '')
		},
	},
	created(){},
	mounted() {},
	render: h => h(App)
}).$mount('#app')
