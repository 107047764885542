<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<AppHeader></AppHeader>

	2021-05-30	init

-->

<template>
	<header class="AppHeader hSpace hSpace--app" :class="elmClasses">

		<div class="AppHeader__blurBackground blurBackground"></div>

		<!-- menu bar, desktop + mobile -->
		<nav class="AppHeader__firstLevel noSelect">
			<!-- desktop -->
			<div class="AppHeader__firstLevelMenu grid grid--gap grid--cols-12" v-if="!app.isMqBelowDt">
				<!-- doity-logo -->
				<h4 class="AppHeader__firstLevelMenuItem AppHeader__firstLevelMenuItem--logo">
					<span class="AppHeader__firstLevelMenuItemTitle">
						<router-link :to="'/'" @click.native="onClickFirstLevelItem()">doity</router-link>
					</span>
				</h4>
				<!-- work -->
				<router-link class="AppHeader__firstLevelMenuItem"
					:class="{'AppHeader__firstLevelMenuItem--active' : isActiveLink('home') }"
					:to="'/'"
					@click.native="onClickFirstLevelItem()">
					<span class="AppHeader__firstLevelMenuItemTitle">Work</span>
				</router-link>
				<!-- directors -->
				<a  class="AppHeader__firstLevelMenuItem"
					:class="{'AppHeader__firstLevelMenuItem--active' : activeSubmenu === 'directors' || isActiveLink('directors') }"
					@click="onClickFirstLevelItem('directors')">
					<span class="AppHeader__firstLevelMenuItemShiftedTitles"
						:class="
							$route.name === 'DirectorsView' ?
							'AppHeader__firstLevelMenuItemShiftedTitles--isShifted' :
							''
						">
						<span class="AppHeader__firstLevelMenuItemTitle">
							Directors
						</span>
						<span class="AppHeader__firstLevelMenuItemTitle">
							{{activeDirectorTitle}}
						</span>
					</span>
				</a>
				<!-- photographers -->
				<a  class="AppHeader__firstLevelMenuItem"
					:class="{'AppHeader__firstLevelMenuItem--active' : activeSubmenu === 'photographers' || isActiveLink('photographers')}"
					@click="onClickFirstLevelItem('photographers')">
					<span class="AppHeader__firstLevelMenuItemShiftedTitles"
						:class="
							$route.name === 'PhotographersView' ?
							'AppHeader__firstLevelMenuItemShiftedTitles--isShifted' :
							''
						">
						<span class="AppHeader__firstLevelMenuItemTitle">
							Photographers
						</span>
						<span class="AppHeader__firstLevelMenuItemTitle">
							{{activePhotographerTitle}}
						</span>
					</span>
				</a>
				<!-- information -->
				<router-link class="AppHeader__firstLevelMenuItem"
					:class="{'AppHeader__firstLevelMenuItem--active' : isActiveLink('information') }"
					:to="'/information/'"
					@click.native="onClickFirstLevelItem()">
					<span class="AppHeader__firstLevelMenuItemTitle">Information</span>
				</router-link>
			</div>
			<!-- phone -->
			<div class="AppHeader__firstLevelMenu grid grid--gap grid--cols-12" v-else>
				<h4 class="AppHeader__firstLevelMenuItem AppHeader__firstLevelMenuItem--logo">
					<span class="AppHeader__firstLevelMenuItemTitle">
						<router-link :to="'/'">doity</router-link>
					</span>
				</h4>
				<a class="AppHeader__firstLevelMenuItem AppHeader__firstLevelMenuItem--toggleMobileMenu" @click="onClickToggleMobileMenu">
					<span class="AppHeader__firstLevelMenuItemTitle">
						<template v-if="isOpen">Close</template>
						<template v-else>Menu</template>
					</span>
				</a>
			</div>
		</nav>

		<!-- desktop, second level dropdowns -->
		<nav class="AppHeader__secondLevel noSelect font font--bold font--sizeBig" ref="AppHeader__secondLevel" @click="onClickSecondLevel" v-if="!app.isMqBelowDt">
			<!-- directors names -->
			<div class="AppHeader__secondLevelMenu AppHeader__secondLevelMenu--directors grid grid--gap grid--cols-12 "
				:class="{'AppHeader__secondLevelMenu--active' : activeSubmenu === 'directors'}">
				<div class="AppHeader__secondLevelMenuItems"
					@mouseleave="onMouseLeaveMenuItems( $event )">
					<router-link class="AppHeader__secondLevelMenuItem"
						v-for="(artist, i) in directors"
						:key="'p'+i"
						:to="app.getLinkWithoutHostname(artist.link)"
						@mouseenter.native="onMouseEnterArtistLink( $event, artist )"
					><span v-html="artist.title"></span></router-link>
				</div>
			</div>
			<!-- photographers names -->
			<div class="AppHeader__secondLevelMenu AppHeader__secondLevelMenu--photographers grid grid--gap grid--cols-12"
				:class="{'AppHeader__secondLevelMenu--active' : activeSubmenu === 'photographers'}">
				<div class="AppHeader__secondLevelMenuItems"
					@mouseleave="onMouseLeaveMenuItems( $event )">
					<router-link class="AppHeader__secondLevelMenuItem"
						v-for="(artist, i) in photographers"
						:key="'p'+i"
						:to="app.getLinkWithoutHostname(artist.link)"
						@mouseenter.native="onMouseEnterArtistLink( $event, artist )"
					><span v-html="artist.title"></span></router-link>
				</div>
			</div>

			<!-- directors media -->
			<div class="AppHeader__secondLevelMedia AppHeader__secondLevelMedia--directors grid grid--gap grid--cols-12"
				v-if="activeSubmenu === 'directors'">
				<div class="AppHeader__secondLevelMediaWrapper" :style="directorsMediaWrapperElmStyles" ref="directorsMedia">
					<MhVideo
						v-if="_.get( hoveredDirector, 'teaserVideo' )"
						:videoObject="_.get( hoveredDirector, 'teaserVideo', false )"
						:autoplay="true"
						:muted="true"
					></MhVideo>
				</div>
			</div>
			<!-- photographers media -->
			<div class="AppHeader__secondLevelMedia AppHeader__secondLevelMedia--photographers grid grid--gap grid--cols-12"
				v-if="activeSubmenu === 'photographers'">
				<div class="AppHeader__secondLevelMediaWrapper" :style="photographersMediaWrapperElmStyles" ref="photographersMedia">
					<template v-if="_.get( hoveredPhotographer, 'teaserType' ) === 'video'">
						<MhVideo
							v-if="_.get( hoveredPhotographer, 'teaserVideo' )"
							:videoObject="_.get( hoveredPhotographer, 'teaserVideo', false )"
							:autoplay="true"
							:muted="true"
						></MhVideo>
					</template>
					<template v-else>
						<MhImage
							v-if="_.get( hoveredPhotographer, 'teaserImage' )"
							:imageObject="_.get( hoveredPhotographer, 'teaserImage', false )"
							:mode="'cover'"
						></MhImage>
					</template>
				</div>
			</div>
		</nav>

		<!-- menu on phone -->
		<nav class="AppHeader__mobileMenu hasOverflow hasOverflow--hideScrollbars font font--bold" v-else-if="isOpen">
			<!-- main menu -->
			<div class="AppHeader__mobileMenuPrimaryItems">
				<router-link class="AppHeader__mobileMenuRow" :to="'/'">Work</router-link>
				<div class="AppHeader__mobileMenuRow AppHeader__mobileMenuGroupHeader"
					:class="{'AppHeader__mobileMenuGroupHeader--isActive' : mobileActiveSubmenu === 'directors'}"
					@click="onClickToggleMobileMenuGroup( $event, 'directors')"
					role="button">
					Directors <span>▼</span>
				</div>
				<ul v-show-slide="mobileActiveSubmenu === 'directors'">
					<li v-for="(artist, i) in directors" :key="'d'+i">
						<router-link class="AppHeader__mobileMenuRow"
							:to="app.getLinkWithoutHostname( artist.link )"
						><span v-html="artist.title"></span></router-link>
					</li>
				</ul>
				<div class="AppHeader__mobileMenuRow AppHeader__mobileMenuGroupHeader"
					:class="{'AppHeader__mobileMenuGroupHeader--isActive' : mobileActiveSubmenu === 'photographers'}" @click="onClickToggleMobileMenuGroup( $event, 'photographers')" role="button">
					Photographers <span>▼</span>
				</div>
				<ul v-show-slide="mobileActiveSubmenu === 'photographers'">
					<li v-for="(artist, i) in photographers" :key="'p'+i">
						<router-link class="AppHeader__mobileMenuRow"
							:to="app.getLinkWithoutHostname( artist.link )"
						><span v-html="artist.title"></span></router-link>
					</li>
				</ul>
				<router-link class="AppHeader__mobileMenuRow" :to="'/information/'">Information</router-link>
			</div>
			<!-- spacer -->
			<div class="flex flex--grow"></div>
			<!-- footer menu -->
			<div class="AppHeader__mobileMenuSecondaryItems">
				<template v-for="(link, i) in _.get(app.acfOptions, 'footerLinks', [])">
					<router-link class="AppHeader__mobileMenuRow" v-if="link.type=='internal'" :to="app.getLinkWithoutHostname( link.internalLink )" :key="i">{{link.title}}</router-link>
					<a class="AppHeader__mobileMenuRow" v-if="link.type=='external'" :href="link.externalUrl" :key="i" :target="link.newTab ? '_blank' : false">{{link.title}}</a>
				</template>
			</div>
		</nav>

		<div class="AppHeader__debugBox hSpace hSpace--app" v-if="isOpen">
			<button @click="doCloseSubmenu()">doCloseSubmenu()</button> <br/>
			isOpen: {{isOpen}} <br/>
			activeSubmenu: {{activeSubmenu}} <br/>
			hoveredLinkType: {{hoveredLinkType}} <br/>
			hoveredDirector: {{hoveredDirector}} <br/>
			hoveredPhotographer: {{hoveredPhotographer}} <br/>
			<br/>
		</div>

		<!--
			<pre name="directors">{{directors}}</pre>
			<pre name="photographers">{{photographers}}</pre>
		-->
	</header>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import MhImage from './MhImage/MhImage.vue'
	import MhVideo from './MhVideo/MhVideo.vue'

	export default {
		name: 'AppHeader',
		components: {
			MhImage,
			MhVideo,
		},
		mixins: [],
		props: {},
		data() {
			return {
				isOpen              : false,
				activeSubmenu       : undefined,
				mobileActiveSubmenu : undefined,
				hoveredLinkType     : undefined,
				hoveredDirector     : undefined,
				hoveredPhotographer : undefined,
				isFadeout           : false,
			}
		},
		watch: {
			activeSubmenu: {
				handler: function( to, from ){
					if( to ){
						EventBus.$emit('AppHeader Submenu is open')
					}
					else{
						EventBus.$emit('AppHeader Submenu is closed')
					}
				},
				immediate: true,
				deep: false,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isOpen ) classes.push(this.$options.name + '--isOpen')
				if( this.isFadeout ) classes.push(this.$options.name + '--isFadeout')

				return classes
			},
			directors(){
				const directors = []

				this._.forEach( this.app.directors, (o)=>{
					directors.push({
						title       : this._.get( o, 'title.rendered' ),
						type        : 'director',
						link        : this._.get( o, 'link' ),
						teaserVideo : this._.get( o, 'acf.teaserVideo', false ),
					})
				})

				return directors
			},
			photographers(){
				const photographers = []

				this._.forEach( this.app.photographers, (o)=>{
					photographers.push({
						title       : this._.get( o, 'title.rendered' ),
						type        : 'photographer',
						link        : this._.get( o, 'link' ),
						teaserType  : this._.get( o, 'acf.teaserType' ),
						teaserImage : this._.get( o, 'acf.teaserImage', false ),
						teaserVideo : this._.get( o, 'acf.teaserVideo', false ),
					})
				})

				return photographers
			},
			activeDirectorTitle(){
				const items = this.app.directors
				const isValidView = this.$route.name === 'DirectorsView' ? true : false

				const activeSlug = isValidView ? this._.get( this.$route, 'params.slug' ) : undefined
				const activeItem = this._.find( items, { slug : activeSlug } )
				const title = this._.get( activeItem, 'title.rendered' )

				/*
				console.log( this.$route )
				console.log( activeSlug )
				console.log( activeItem )
				console.log( title )
				*/

				return title
			},
			activePhotographerTitle(){
				const items = this.app.photographers
				const isValidView = this.$route.name === 'PhotographersView' ? true : false

				const activeSlug = isValidView ? this._.get( this.$route, 'params.slug' ) : undefined
				const activeItem = this._.find( items, { slug : activeSlug } )
				const title = this._.get( activeItem, 'title.rendered' )

				/*
				console.log( this.$route )
				console.log( activeSlug )
				console.log( activeItem )
				console.log( title )
				*/

				return title
			},
			directorsMediaWrapperElmStyles(){
				const styles = {}

				if( this.hoveredLinkType == 'director' ) styles.opacity = 1

				return styles
			},
			photographersMediaWrapperElmStyles(){
				const styles = {}

				if( this.hoveredLinkType == 'photographer' ) styles.opacity = 1

				return styles
			},
		},
		methods: {
			onClickFirstLevelItem( type, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClickFirstLevelItem( type )', type)
					console.log('type:', type)
					console.groupEnd()
				}

				if( this.activeSubmenu == type ){
					this.doCloseSubmenu()
				}else{
					this.doOpenSubmenu( type )
				}

				// if no type give: just close the submenu
				if( !type ) this.doCloseSubmenu()
			},
			onClickSecondLevel( e, doLog = false ){
				const elm = e.target
				const isMd = this.app.isMqBelowDt

				if( doLog && !isMd ){
					console.groupCollapsed( this.$options.name, '• onClickSecondLevel()')
					console.log('elm:', elm)
					console.groupEnd()
				}

				setTimeout(()=>{
					if(!isMd) this.doCloseSubmenu()
				}, 25)
			},
			onClickToggleMobileMenu( e, doLog = false ){
				this.isOpen = !this.isOpen

				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClickToggleMobileMenu()')
					console.log('isOpen:', this.isOpen)
					console.groupEnd()
				}
			},
			onClickToggleMobileMenuGroup( e, group, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClickToggleMobileMenuGroup( group )')
					console.log('group:', group)
					console.groupEnd()
				}

				if( group === this.mobileActiveSubmenu ){
					this.mobileActiveSubmenu = undefined
				}else{
					this.mobileActiveSubmenu = group
				}
			},
			doOpenSubmenu( type, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• doOpenSubmenu( type )')
					console.log('type:', type)
					console.groupEnd()
				}

				this.activeSubmenu = type
				this.isOpen = true
			},
			doCloseSubmenu( doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• doCloseSubmenu()')
					console.groupEnd()
				}

				this.activeSubmenu = undefined
				this.isOpen = false
			},
			onMouseEnterArtistLink( e, artist, doLog = false ){
				const elm           = e.target
				const type          = artist.type
				const elmRect       = elm.getBoundingClientRect()
				const parentElm     = this.$refs.AppHeader__secondLevel
				const parentElmRect = parentElm.getBoundingClientRect()
				let   elmTop        = elmRect.top - parentElmRect.top
				let   mediaWrapperElm

				if( type == 'director' ){
					mediaWrapperElm = this.$refs.directorsMedia
					this.hoveredDirector = artist
				}
				if( type == 'photographer' ){
					mediaWrapperElm = this.$refs.photographersMedia
					this.hoveredPhotographer = artist
				}

				if( mediaWrapperElm ){
					//this.$nextTick(()=>{
					const viewportHeight = window.innerHeight
					const mediaWrapperElmRect = mediaWrapperElm.getBoundingClientRect()
					const fromBottom = viewportHeight - ( elmTop + mediaWrapperElmRect.height + 40 )

					// group groupCollapsed
					if( doLog ){
						console.groupCollapsed( this.$options.name, '• onMouseEnterArtistLink( e )')
						console.log('e:', e)
						console.log('artist:', artist)
						console.log('type:', type)
						console.log('parentElm:', parentElm)
						console.log('parentElmRect:', parentElmRect)
						console.log('elm:', elm)
						console.log('elmRect:', elmRect)
						console.log('elmTop:', elmTop)
						console.log('mediaWrapperElm:', mediaWrapperElm)
						console.log('mediaWrapperElmRect:', mediaWrapperElmRect)
						console.log('viewportHeight:', viewportHeight)
						console.log('fromBottom:', fromBottom)
						console.groupEnd()
					}

					// wenn das elm den viewport unten verlässt:
					// wird hier der top-wert dementsprechend korrigiert
					if( fromBottom < 0 ) elmTop = elmTop + fromBottom

					mediaWrapperElm.style.transform = 'translateY('+elmTop+'px)'

					//})
				}

				this.hoveredLinkType = type

			},
			onMouseLeaveMenuItems( e, doLog = false ){
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onMouseLeaveMenuItems( e )')
					console.log('e:', e)
					console.groupEnd()
				}

				this.hoveredLinkType     = undefined
				this.hoveredDirector     = undefined
				this.hoveredPhotographer = undefined
			},
			isActiveLink( str, doLog = false ){
				const currentView = this.$route.name
				const routeSlug   = this.$route.params.slug
				let isActive = false

				if( currentView == 'HomeView' && str == 'home' ) isActive = true
				if( currentView == 'PhotographersView' && str == 'photographers' ) isActive = true
				if( currentView == 'DirectorsView' && str == 'directors' ) isActive = true
				if( routeSlug   == 'information' && str == 'information' ) isActive = true
				if( routeSlug   == 'information' && str == 'information' ) isActive = true

				if( doLog ){
					console.groupCollapsed( this.$options.name, '• isActiveLink( str )')
					console.log('str:', str)
					console.log('routeSlug:', routeSlug)
					console.groupEnd()
				}

				return this.app.isReady ? isActive : false
			},
		},
		created(){},
		mounted(){
			EventBus.$on('ProjectOverlay is open', ()=>{ this.isFadeout = true })
			EventBus.$on('ProjectOverlay is closed', ()=>{ this.isFadeout = false })

			// close menu after route change
			this.$router.afterEach( (to, from) => {
				const isMd = this.app.isMqBelowDt

				//this.doCloseSubmenu()

				// only on md, dt is handled otherwise
				// use short delay to let the page render (really needed?)
				if( isMd ){
					setTimeout(()=>{
						this.isOpen = false
					}, 75)
				}
			})
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	@AppHeader : {
		dtHeight: @app[dtHeaderHeight];
		mdHeight: @app[mdHeaderHeight];
		showBackgroundTransistionDuration: 0.5s;
		slideOutTransistionDuration: 0.35s;
		mediaElmTransitionDuration: 0.1s;
	}

	.AppHeader { // debug
		[showBorders4] * {
			outline: 1px solid red;
			background-color: fade(red, 75);
		}
		[showBorders4] &__firstLevel {
			.label('firstLevel');
			.outline(orange);
		}
		[showBorders4] &__secondLevel {
			.label('secondLevel');
			.outline(green);
		}
		[showBorders4] &__secondLevelMenu {
			outline: 1px solid fade(green, 50);
		}
	}
	.AppHeader { // vars
	}
	.AppHeader { // layout + styling
		position: fixed;
		top: 0; left: 0; height: 100vh;
		width: calc( 100vw - var(--scrollbarWidth) );
		z-index: 10; // above content

		display: flex;
		flex-direction: column;
		color: white;

		transition: opacity @transitions[projectOverlayDuration] ease;

		&--isFadeout > * {
			opacity: 0;
		}

		&__firstLevel {
			position: relative;
			display: flex;
			flex-direction: column;
			font-weight: bold;
			z-index: 3;

			@media @mediaQueries[dt] {
				height: @AppHeader[dtHeight];
			}
			@media @mediaQueries[md] {
				height: @AppHeader[mdHeight];
			}

			&Menu { position: relative; height: 100%; }
		}
		&__firstLevelMenuItem {
			display: flex;
			//align-items: center;
			grid-column: span 2;
			color: inherit;
			transition: all 0.15s ease;

			&:hover { color: @swatches[textRed]; }
			&--active { color: @swatches[textRed]; }
			//&.routerLink--exactActive { color: @swatches[textRed]; }
			//&.routerLink--active { color: @swatches[textRed]; }

			&Title {
				//background-color: fade( green, 50 );

				display: flex;
				align-items: center;
				//flex-grow: 1; // full height, needed for title toggle feature in DirectorsView and PhotographersView
				@media @mediaQueries[dt] {
					height: @AppHeader[dtHeight];
				}
				@media @mediaQueries[md] {
					height: @AppHeader[mdHeight];
				}
			}

			&--logo {
				display: flex;

				@media @mediaQueries[dt] { grid-column: span 4; }
				@media @mediaQueries[md] { grid-column: span 8; }

				a {
					background-image: url('./../assets/imgs/DOITY_VEKTOR_LOGO_1.svg');
					background-size: contain;
					background-repeat: no-repeat;
					background-position: left center;
					filter: invert(100%);

					flex-grow: 1;
					color: transparent !important;
					width: 50%;
				}
			}
			&--toggleMobileMenu {
				//color: yellow;
				justify-content: flex-end;

				@media @mediaQueries[md] { grid-column: span 4; }
			}
		}

		&__firstLevelMenuItemShiftedTitles {
			//background-color: fade( red, 25 );

			display: flex;
			display: block;
			overflow: hidden;

			@media @mediaQueries[dt] {
				height: @AppHeader[dtHeight];
			}
			@media @mediaQueries[md] {
				height: @AppHeader[mdHeight];
			}

			& > * { transition: all 0.2s ease;}

			&--isShifted > * { transform: translateY( -100% ); }
			&--isShifted:hover > * { transform: translateY( 0% ); }
			.AppHeader--isOpen &--isShifted > * { transform: translateY( 0% ); }
		}
		&__firstLevelMenuItemToggleTitle {
			display: flex;
			height: 100%;
			outline: 1px solid red;
		}

		&__secondLevel {
			position: relative;
			flex-grow: 1;
			z-index: 2;
		}
		&__secondLevelMenu {
			position: absolute;
			top: 0; left: 0; right: 0;
			transition: all @AppHeader[slideOutTransistionDuration] ease;
			will-change: transform;

			@media @mediaQueries[dt] {
				transform: translateY( calc(-100% - @AppHeader[dtHeight] - 1em ) );
			}
			@media @mediaQueries[md] {
				transform: translateY( calc(-100% - @AppHeader[mdHeight] - 1em ) );
			}

			&--active { transform: translateY(0%); }
			&--directors &Items { grid-column: 7 / span 6; }
			&--photographers &Items { grid-column: 9 / span 4; }
		}
		&__secondLevelMenuItem {
			color: inherit;
			display: block;
			transition: all 0.15s ease;

			&.routerLink--exactActive,
			&:hover {
				color: @swatches[textRed];
			}
		}
		&__secondLevelMenuItems {}
		&__secondLevelMenuItem { // safari fix: er zeigt eine kleine weiße ecke an den buchstaben an, wo die glyphe die box verlässt
			&s { margin-left: -5px;  }
			padding-left: 5px;
		}
		&__secondLevelMedia {
			position: absolute;
			top: 0; left: 0; right: 0;
			pointer-events: none;

			&Wrapper {
				//background-color: fade(yellow, 25);

				position: relative;
				transition: opacity @AppHeader[mediaElmTransitionDuration] ease;
				will-change: opacity;
				opacity: 0;
				overflow: hidden; // vermutlich unnötig
			}
			&--directors &Wrapper { // force 16:9
				//background-color: fade(yellow, 25);
				padding-bottom: (9/16)*100%; // 16:9
				grid-column: 1 / span 6;
			}
			&--photographers &Wrapper { // force 16:9
				//background-color: fade(yellow, 25);
				padding-bottom: (9/16)*100%; // 16:9
				grid-column: 3 / span 6;
			}

			// teaser video für directors
			.MhVideo {
				//outline: 2px solid red;
				//display: none !important;

				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;

				&__inner {
					padding-bottom: (9/16)*100% !important; // 16:9
					//background-color: fade(black, 35);
					background-color: transparent;
				}
				&__videoElm {
					object-fit: cover;
				}
			}
			// teaser image für photographers, force 16:9
			.MhImage {
				//outline: 2px solid red;
				//display: none !important;

				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;

				&__imageWrapper {
					padding-bottom: (9/16)*100% !important; // 16:9
					background-color: fade(black, 35);
					background-color: transparent;
				}
			}
		}

		// handle click trough via pointer-events
		& {
			pointer-events: none;
		}
		&__firstLevel {
			pointer-events: auto;
		}
		&--isOpen &__secondLevel {
			pointer-events: auto;
		}
		&--isOpen &__mobileMenu {
			pointer-events: auto;
		}

		// handle background blur expand/shrink
		&__blurBackground {
			//position: relative;
			will-change: height;
			//z-index: 10;
			//background-color: @swatches[textRed] !important;

			@media @mediaQueries[dt] {
				height: @AppHeader[dtHeight];
			}
			@media @mediaQueries[md] {
				height: @AppHeader[mdHeight];
			}
		}
		&--isOpen &__blurBackground {
			//opacity: 0;
			height: 100%;
		}
		&--isOpen &__secondLevel &__blurBackground {
			opacity: 1;
		}

		// mobile menu
		&__mobileMenu {
			position: relative;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			z-index: 2;
			padding-top: 1.5em;
			font-size: 14px;
			line-height: 1em;

			&PrimaryItems {
				//background-color: fade( blue, 20 );
			}
			&SecondaryItems {
				//background-color: fade( green, 20 );
				padding-bottom: 5.5rem; // prevents the ios 100vh is not the viewport behaviour
			}

			&Row {
				//outline: 1px solid red;

				display: block;
				padding: 0.75em 0;
				cursor: pointer;
			}
			&GroupHeader { // toggle indicator
				//background-color: @swatches[textRed];

				span {
					transition: all 0.2s ease;
					display: inline-block;
					font-size: 0.75em;
					padding: 0 0.5em;
				}

				&--isActive {
					//background-color: green;

					span {
						transform: rotate(180deg) translateY(15%);
					}
				}
			}

			ul {
				list-style: none;
				padding: 0;
				//margin: 0;
			}
			a {
				color: inherit;
			}
			a.routerLink--exactActive {
				color: @swatches[textRed];
			}
			li:first-child {
				padding-top: 0.5em;
			}
			li:last-child {
				padding-bottom: 1.5em;
			}
			li a {
				font-size: 24px;
				line-height: 1em;
				padding: 0.15em 0;
			}
		}

		&__debugBox {
			display: none;

			.label('debug');
			.outline( blue );

			padding: 0.5em;
			max-height: 200px;
			overflow: auto;
			z-index: 2;
		}
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
