<template>
	<div class="PageContent" :class="elmClasses" :data-title="_.get( post, 'title.rendered')">

		<!-- just start rendering if everything is fetched -->
		<template v-if="app.isReady">

			<!-- text-boxes -->
			<template v-if="_.get( post, 'acf.pageTemplate') == 'textLayout'">

				<br/><br/>

				<!-- smartphones -->
				<template v-if="app.isMqBelowDt">

					<template v-for="(cell, i) in _.get( post, 'acf.textItems')">
						<template v-if="cell.acf_fc_layout != 'box--spacer'">
							<!--
							{{i}} • {{cell.acf_fc_layout}} <br/>
							-->

							<TextBox
								:key="'iyse'+i"
								:text="getTextBoxText( cell )"
							></TextBox>

							<div class="gridRowGap" :key="'ajcii'+i"></div>
							<div class="gridRowGap" :key="'iiewi'+i"></div>

						</template>
					</template>

				</template>

				<!-- desktop -->
				<template v-else>

					<LayoutGrid
						:gap="16"
						:rowHeight="'variable'"
						XXXuseDebugStyles="true">
						<GridCell v-for="(cell, index) in _.get( post, 'acf.textItems')" :key="'cc'+index"
							:columns="cell.columns"
							:rows="1"
							:contentAspectRatio="'by text'"
							:text="getTextBoxText( cell )"
						></GridCell>
					</LayoutGrid>

				</template>

			</template>

			<!-- image- and video-boxes inside a grid -->
			<template v-else>

				<!-- smartphones -->
				<template v-if="app.isMqBelowDt">

					<!-- director-artist -->
					<template v-if="_.get( post, 'type') === 'directors_post'">
						<!-- header with text and hero -->
						<br /><br /><br />

						<TextBox
							:text="_.get( post, 'acf.teaserText' )"
							:textVerticalAlign="'bottom'"
						></TextBox>

						<div class="gridRowGap"></div>

						<!-- manuell grid items -->
						<template v-for="(cell, i) in _.get( post, 'acf.grid.gridItems')">
							<template v-if="cell.acf_fc_layout != 'box--spacer'">
								<MediaBox
									:key="'imso'+i"
									:image="post.slug === 'work' && i === 0 ? false : app.getCellImage( cell, i )"
									:clip="app.getCellClip( cell )"
									:title="app.getCellTitle( cell, i, post )"
									:poster="_.get( app.getCellImage( cell, i ), 'sizes.medium')"
									:projectSlug="app.getCellProjectSlug( cell )"
									:playClipOnHover="true"
									:autoplayClip="app.getCellAutoplayClip( cell, i )"
									:showVideoControls="false"
									:isVideoMuted="true"
									:pageIsVisible="pageIsVisible"
								></MediaBox>
								<!--
								<pre :key="'imsop'+i">{{cell}}</pre>
								-->
								<div class="gridRowGap" :key="'iqaxi'+i"></div>
							</template>
						</template>
					</template>

					<!-- photographer-artist -->
					<template v-else-if="_.get( post, 'type') === 'photographers_post'">
						<!-- header with text and hero -->
						<br /><br /><br />

						<TextBox
							:text="_.get( post, 'acf.teaserText' )"
							:textVerticalAlign="'bottom'"
						></TextBox>

						<div class="gridRowGap"></div>

						<!--
						<MediaBox
							:image="_.get( post, 'acf.teaserImage' )"
						></MediaBox>
						<div class="gridRowGap"></div>
						-->

						<!-- manuell grid items -->
						<template v-for="(cell, i) in _.get( post, 'acf.grid.gridItems')">
							<template v-if="cell.acf_fc_layout != 'box--spacer'">

								<MediaBox
									:key="'dfgei'+i"
									XXXimage="$route.name === 'HomeView' && i === 0 ? false : app.getCellImage( cell, i )"
									:image="post.slug === 'work' && i === 0 ? false : app.getCellImage( cell, i )"
									:clip="app.getCellClip( cell )"
									:poster="_.get( app.getCellImage( cell, i ), 'sizes.medium')"
									:title="app.getCellTitle( cell, i, post )"

									:projectSlug="app.getCellProjectSlug( cell )"
									:playClipOnHover="true"
									:autoplayClip="app.getCellAutoplayClip( cell, i )"
									:showVideoControls="false"
									:isVideoMuted="true"
									:pageIsVisible="pageIsVisible"
								></MediaBox>

								<div class="gridRowGap" :key="'iewqi'+i"></div>

							</template>
						</template>
					</template>

					<!-- page -->
					<template v-else>

						<template v-for="(cell, i) in _.get( post, 'acf.grid.gridItems')">
							<template v-if="cell.acf_fc_layout != 'box--spacer'">
								<MediaBox
									:key="'i'+i"
									XXXimage="post.slug === 'work' && i === 0 ? false : app.getCellImage( cell, i )"
									:image="app.getCellImage( cell, i )"
									:clip="app.getCellClip( cell )"
									:title="app.getCellTitle( cell, i, post )"
									:projectSlug="app.getCellProjectSlug( cell )"
									:playClipOnHover="true"
									:autoplayClip="app.getCellAutoplayClip( cell, i )"
									:showVideoControls="false"
									:isVideoMuted="true"
									:pageIsVisible="pageIsVisible"
								></MediaBox>

								<div class="gridRowGap" :key="'iqii'+i"></div>

							</template>
						</template>

					</template>

				</template>

				<!-- desktop -->
				<template v-else>

					<!-- director-artist -->
					<template v-if="_.get( post, 'type') === 'directors_post'">
						<!-- header with text and hero -->
						<LayoutGrid v-if="_.get(post, 'acf.grid')"
							:gap="16"
							:rowHeight="'fixed'">
							<GridCell class="maxWidthText"
								:columns="4"
								:rows="2"
								:aspectRatio="'auto'"
								:text="_.get( post, 'acf.teaserText' )"
								:textVerticalAlign="'bottom'"
								:pageIsVisible="pageIsVisible"
							></GridCell>
							<GridCell
								:columns="8"
								:rows="2"
								:aspectRatio="'16:9'"
								:clip="app.getCellClip( _.get(post, 'acf.grid.gridItems[0]') )"
								:title="app.getCellTitle( _.get(post, 'acf.grid.gridItems[0]') )"
								:projectSlug="app.getCellProjectSlug( _.get(post, 'acf.grid.gridItems[0]') )"
								:autoplayClip="true"
								:preloadClip="true"
								:isVideoMuted="true"
								:pageIsVisible="pageIsVisible"
							></GridCell>
							<!--
							<GridCell
								:columns="8"
								:rows="2"
								:aspectRatio="'16:9'"
								:clip="_.get( post, 'acf.teaserVideo' )"
								:autoplayClip="true"
								:preloadClip="true"
								:isVideoMuted="true"
								:pageIsVisible="pageIsVisible"
							></GridCell>
							-->
						</LayoutGrid>
						<!-- manuell grid items -->
						<LayoutGrid v-if="_.get(post, 'acf.grid')"
							:gap="16"
							:rowHeight="_.get(post, 'acf.grid.rowHeight')">
							<GridCell v-for="(cell, i) in _.slice( _.get(post, 'acf.grid.gridItems'), 1 )" :key="'lo'+i"
								:columns="cell.columns"
								:rows="cell.rows"
								:cellAspectRatio="app.getCellAspectRatio( cell )"
								:contentAspectRatio="app.getCellContentAspectRatio( cell )"

								:projectSlug="app.getCellProjectSlug( cell )"
								:text="app.getCellText( cell )"
								:textVerticalAlign="app.getCellTextVerticalAlign( cell )"
								:title="app.getCellTitle( cell, i, post )"
								XXXimage="$route.name === 'HomeView' && i === 0 ? false : app.getCellImage( cell )"
								:image="post.slug === 'work' && i === 0 ? false : app.getCellImage( cell, i )"
								:clip="app.getCellClip( cell )"
								:poster="_.get( app.getCellImage( cell, i ), 'sizes.medium')"
								XXXautoplayClip="$route.name === 'HomeView' && i === 0 ? true : false"
								:autoplayClip="post.slug === 'work' && i === 0 ? true : false"
								:isVideoMuted="true"
								:pageIsVisible="pageIsVisible"
							></GridCell>
						</LayoutGrid>
					</template>
					<!-- photographer-artist -->
					<template v-else-if="_.get( post, 'type') === 'photographers_post'">
						<!-- header with text and hero -->
						<LayoutGrid v-if="_.get(post, 'acf.grid')"
							:gap="16"
							:rowHeight="'fixed'">
							<GridCell class="maxWidthText"
								:columns="4"
								:rows="2"
								:aspectRatio="'auto'"
								:text="_.get( post, 'acf.teaserText' )"
								:textVerticalAlign="'bottom'"
								:pageIsVisible="pageIsVisible"
							></GridCell>
							<GridCell
								:columns="8"
								:rows="2"
								:aspectRatio="'16:9'"
								:clip="_.get( post, 'acf.teaserType' ) === 'video' ? _.get( post, 'acf.teaserVideo' ) : false"
								:autoplayClip="true"
								:isVideoMuted="true"
								:image="_.get( post, 'acf.teaserType' ) === 'video' ? false : _.get( post, 'acf.teaserImage' )"
								:pageIsVisible="pageIsVisible"
							></GridCell>
						</LayoutGrid>
						<!-- manuell grid items -->
						<LayoutGrid v-if="_.get(post, 'acf.grid')"
							:gap="16"
							:rowHeight="_.get(post, 'acf.grid.rowHeight')">
							<GridCell v-for="(cell, i) in _.get(post, 'acf.grid.gridItems')" :key="'cs'+i"
								:columns="cell.columns"
								:rows="cell.rows"
								:cellAspectRatio="app.getCellAspectRatio( cell )"
								:contentAspectRatio="app.getCellContentAspectRatio( cell )"

								:projectSlug="app.getCellProjectSlug( cell )"
								:text="app.getCellText( cell )"
								:textVerticalAlign="app.getCellTextVerticalAlign( cell )"
								:title="app.getCellTitle( cell, i, post )"
								:image="app.getCellImage( cell )"
								:clip="app.getCellClip( cell )"
								:poster="_.get( app.getCellImage( cell, i ), 'sizes.medium')"
								XXXautoplayClip="$route.name === 'HomeView' && i === 0 ? true : false"
								:autoplayClip="post.slug === 'work' && i === 0 ? true : false"
								:isVideoMuted="true"
								:pageIsVisible="pageIsVisible"
							></GridCell>
						</LayoutGrid>
					</template>
					<!-- page -->
					<template v-else>
						<!-- manuell grid items -->
						<LayoutGrid v-if="_.get(post, 'acf.grid')"
							:gap="16"
							:rowHeight="_.get(post, 'acf.grid.rowHeight')">
							<GridCell v-for="(cell, i) in _.get(post, 'acf.grid.gridItems')" :key="'mkioe'+i"
								:class="cell.acf_fc_layout"
								:columns="cell.columns"
								:rows="cell.rows"
								:cellAspectRatio="app.getCellAspectRatio( cell )"
								:contentAspectRatio="app.getCellContentAspectRatio( cell )"

								:projectSlug="app.getCellProjectSlug( cell )"
								:text="app.getCellText( cell, i, post )"
								:textVerticalAlign="app.getCellTextVerticalAlign( cell )"
								:title="app.getCellTitle( cell, i, post )"
								XXXimage="$route.name === 'HomeView' && i === 0 ? false : app.getCellImage( cell, i )"
								:image="post.slug === 'work' && i === 0 ? false : app.getCellImage( cell, i )"
								:poster="_.get( app.getCellImage( cell, i ), 'sizes.medium')"
								:clip="app.getCellClip( cell, post )"
								:autoplayClip="app.getCellAutoplayClip( cell, i )"
								:isVideoMuted="true"
								:playClipOnHover="true"
								:pageIsVisible="pageIsVisible"
							></GridCell>
						</LayoutGrid>
					</template>

				</template>

			</template>

			<div class="flex flex--grow"></div>

			<AppFooter></AppFooter>

		</template>

		<!--
		<pre name="post">{{post}}</pre>
		-->

	</div>
</template>

<script>
	// @ is an alias to /src
	//import MhImage from '@/components/MhImage/MhImage.vue'
	import { EventBus } from '@/event-bus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	//import MhRouterView from './MhRouterView/v2/MhRouterView.vue'
	import LayoutGrid from './LayoutGrid.vue'
	import GridCell from './GridCell.vue'
	import MediaBox from './MediaBox.vue'
	import TextBox from './TextBox.vue'
	import AppFooter from './AppFooter.vue'

	export default {
		name: 'PageContent',
		components: {
			LayoutGrid,
			GridCell,
			MediaBox,
			TextBox,
			AppFooter,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {
			pageIsVisible: {
				type    : [Boolean],
				default : false,
			},
			post: {
				type    : [Object, Boolean],
				default : undefined,
			},
		},
		data(){
			return {}
		},
		watch: {
			pageIsVisible: {
				handler: function( to, from, doLog = false ) {
					if( doLog ){
						console.group( this.$options.name + ' • watcher • pageIsVisible', from, to)
						console.groupEnd()
					}
				},
				immediate : true,
				deep: false,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				const classes = []



				return classes
			},
		},
		methods: {
			getTextBoxText( cell ){
				const htmlArr = []

				if( cell.title ) htmlArr.push( '<h2 class="headline headline--big font font--bold">' )
				if( cell.title ) htmlArr.push( cell.title )
				if( cell.title && !this.app.isMqBelowDt ) htmlArr.push( '<br/><br/>' )
				if( cell.title ) htmlArr.push( '</h2>' )
				if( cell.title && this.app.isMqBelowDt ) htmlArr.push( '<br/>' )

				if( cell.text )  htmlArr.push( '<div>' )
				if( cell.text )  htmlArr.push( cell.text )
				if( cell.text )  htmlArr.push( '<br/>' )
				if( cell.text )  htmlArr.push( '</div>' )

				return htmlArr.join('')
			},
		},
		mounted(){},
	}
</script>

<style lang="less">
	//@import "../less/includes/grid.include.less";
	@import "../less/vars.less";

	.PageContent {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		//& > * { outline: 1px solid red; }
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
