<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ImageLightbox></ImageLightbox>

	2019-06-08	init

-->

<template>
	<div class="ImageLightbox hSpace hSpace--app" :class="elmClasses">
		<div class="ImageLightbox__inner">123</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'ImageLightbox',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			/*
			myProp: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
			*/
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){
			EventBus.$on('Open Lightbox', ( payload, doLog = true )=>{

				const startWith = payload.startWith
				const images    = payload.images
				//const post      = payload.post

				if( doLog ){
					console.group( this.$options.name, '• EventBus "Open Lightbox"' )
					console.log('startWith:', startWith)
					console.log('images:', images)
					//console.log('post:', post)
					console.groupEnd()
				}
			})
		},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.ImageLightbox { // debug
		&__inner {
			background-color: fade( red, 50 );
		}
	}
	.ImageLightbox {
		position: fixed;
		top: 0; left: 0; height: 100vh;
		width: calc( 100vw - var(--scrollbarWidth) );
		z-index: 20;
		pointer-events: none;
		display: flex;

		&__inner {
			flex-grow: 1;
		}

	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
