<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<AppBody></AppBody>

	2021-06-12	init

-->

<template>
	<div class="AppBody" :class="elmClasses">
		<div class="AppBody__horizontal"
			 v-for="(firstLevelItem, index1) in theSitemap"
			 :class="'AppBody__horizontal--position-' + firstLevelItem.position"
			 :style="{
				visibility : firstLevelItem.isVisible ? 'visible' : 'hidden',
			 }"
			 :aria-hidden="!firstLevelItem.position==='center'"
			 :key="index1"
			 @transitionstart="onTransitionStart( $event, firstLevelItem )"
			 @transitionend="onTransitionEnd( $event, firstLevelItem )">

			<div class="AppBody__vertical hasOverflow hasOverflow--hideScrollbars customScrollbars hSpace hSpace--app vSpace vSpace--app"
				 v-for="(secondLevelItem, index2) in firstLevelItem.items"
				 :class="
				 	'AppBody__vertical--position-' + secondLevelItem.position
				 "
				 :style="{
					visibility : secondLevelItem.isVisible ? 'visible' : 'hidden',
					transitionDuration : secondLevelItem.quickTransition ? '25ms' : '',
				 }"
				 :key="index2"
				 :ref="index1+'_'+index2"
				 :data-id="index1+'_'+index2"
				@transitionend="onTransitionEnd( $event, secondLevelItem )">

				<!--
				das v-if geht nicht, weil chrome 92 nur die
				dynamische erstellung von 75 video-tags zulässt.
				https://stackoverflow.com/a/68503181
				was ein quatsch ...
				-->

				<PageContent
					v-if="secondLevelItem.isVisible"
					:pageIsVisible="secondLevelItem.isVisible"
					:post="secondLevelItem.post"
				></PageContent>

			</div>

		</div>

		<div class="AppBody__debug">
			<!-- all pages list -->
			<div class="AppBody__debugGroup" v-for="(firstLevelItem, index) in theSitemap" :key="'j'+index">
				<span v-if="firstLevelItem.position==='left'"   :style="{ display: 'inline-block', width : '1.5em', }">←</span>
				<span v-if="firstLevelItem.position==='center'" :style="{ display: 'inline-block', width : '1.5em', }">↔</span>
				<span v-if="firstLevelItem.position==='right'"  :style="{ display: 'inline-block', width : '1.5em', }">→</span>

				<span v-if="firstLevelItem.isVisible"  :style="{ display: 'inline-block', width : '1.5em', }">✅</span>
				<span v-if="!firstLevelItem.isVisible" :style="{ display: 'inline-block', width : '1.5em', }">❌</span>

				<div v-for="(secondLevelItem, index) in firstLevelItem.items" :key="'kldfo'+index">
					<router-link
						:to="secondLevelItem.path">
						<hr/>
						<span v-if="secondLevelItem.isVisible"  :style="{ display: 'inline-block', width : '1.5em', }">✅</span>
						<span v-if="!secondLevelItem.isVisible" :style="{ display: 'inline-block', width : '1.5em', }">❌</span>
						<span v-if="secondLevelItem.position==='top'"    :style="{ display: 'inline-block', width : '1em', }">↑</span>
						<span v-if="secondLevelItem.position==='center'" :style="{ display: 'inline-block', width : '1em', }">↕</span>
						<span v-if="secondLevelItem.position==='bottom'" :style="{ display: 'inline-block', width : '1em', }">↓</span>
						{{_.get( secondLevelItem, 'id' )}}
						{{_.get( secondLevelItem, 'post.title.rendered' )}}
						<!--
						{{_.get( secondLevelItem, 'position' )}}
						{{_.get( secondLevelItem, 'isVisible' )}}
						-->
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import PageContent from './PageContent.vue'

	export default {
		name: 'AppBody',
		components: {
			PageContent,
		},
		mixins: [],
		props: {
			sitemap: {
				type     : [Array, Boolean],
				default  : false,
				required : true,
			},
		},
		data(){
			return {
				theSitemap   : [],
				fromId       : undefined,
				toId         : undefined,
				//isAnimating  : false,
				validTargets : ['AppBody__horizontal', 'AppBody__vertical']
			}
		},
		watch: {
			'$route.path': {
				handler: function( to, from ){
					if( this.sitemap) this.gotoView( from, to )
				},
				immediate: false,
				deep: false,
			},
			'sitemap': {
				handler: function( to, from ){
					if( to ) this.setTheSitemap( to )
					if( to ) this.gotoView( undefined, this.$route.path )
				},
				immediate: true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//if( this.isAnimating ) classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			allItems(){
				const allItems = []
				this.theSitemap.forEach((firstLevelItem, index1)=>{
					firstLevelItem.items.forEach((secondLevelItem, index2)=>{
						allItems.push( secondLevelItem )
					})
				})

				return allItems
			},
		},
		methods: {
			onTransitionStart( e, item, doLog = false ){
				const validTargets = this.validTargets
				const target = e.target
				let isValidTarget = false

				// check if target has valid class
				// to prevent running on other elms
				validTargets.forEach((c)=>{
					if( target.classList.contains(c) ) isValidTarget = true
				})

				if( isValidTarget ){

					// groupCollapsed group
					if( doLog ){
						console.groupCollapsed( this.$options.name, '• onTransitionStart( e )', item.id)
						console.log('e:', e)
						console.log('item:', item)
						console.log('item.position:', item.position)
						console.groupEnd()
					}

					//this.isAnimating = true

				}
			},
			onTransitionEnd( e, item, doLog = false ){
				const validTargets = this.validTargets
				const target = e.target
				let isValidTarget = false

				// check if target has valid class
				// to prevent running on other elms
				validTargets.forEach((c)=>{
					if( target.classList.contains(c) ) isValidTarget = true
				})

				if( isValidTarget ){
					// groupCollapsed group
					if( doLog ){
						console.groupCollapsed( this.$options.name, '• onTransitionEnd( e )', item.id)
						console.log('e:', e)
						console.log('item:', item)
						console.log('item.position:', item.position)
						//console.log('this:', this)
						console.groupEnd()
					}

					// firstLevelItems + secondLevelItems:
					// Nachdem die transition abgeschlossen ist und das item
					// nicht zentriert ist, wird es augeblendet
					if( item.position !== 'center' ) item.isVisible = false

					// secondLevelItems:
					// wenn sich diese items in einem ausgeblendeten item befinden:
					// wird ihre position wieder auf 'bottom' gesetzt
					// und isVisible wieder auf false
					if( item.position == 'left' || item.position == 'right' ){
						item.items.forEach((secondLevelItem, index2)=>{
							secondLevelItem.position  = index2 === 0 ? 'center' : 'bottom'
							secondLevelItem.isVisible = false
						})
					}

					// extra prop, die zu beginn der animation vergeben wurde
					// wird hier wieder entfernt
					//this.isAnimating = false

					// secondLevelItems:
					e.target.scrollTop = 0

					// prevents bubble from vertical to horizontal
					// useless trigger
					e.stopPropagation()
				}
			},
			setTheSitemap( sitemap, doLog = false ){ // transform the given sitemap prop as data var
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• setTheSitemap( sitemap )')
					console.log('sitemap', sitemap)
					console.groupEnd()
				}

				let theSitemap = []

				sitemap.forEach((v1,k1)=>{
					const item = {
						id        : k1+'',
						position  : 'right',
						isVisible : false,
						items     : [],
					}

					v1.items.forEach((v2,k2)=>{
						const secondLevelItem = this._.cloneDeep( v2 )
						const link = this.app.getLinkWithoutHostname( v2.post.link )

						secondLevelItem.id              = k1 + "_" + k2
						secondLevelItem.post.link       = link
						secondLevelItem.position        = 'bottom'
						secondLevelItem.isVisible       = false
						secondLevelItem.quickTransition = false

						item.items.push( secondLevelItem )
					})

					theSitemap.push( item )
				})

				this.theSitemap = theSitemap
			},
			gotoView( fromPath, toPath, doLog = false ){
				//const currentActiveView = this.views.pages.find((o)=>{ return o.position === 'center' })
				//const nextActiveView    = this.views.pages.find((o)=>{ return o.path === path })

				// firstLevelItems: find current and next index
				let fromFirstLevelIndex = undefined
				let toFirstLevelIndex   = undefined
				this.theSitemap.forEach((firstLevelItem, index1)=>{
					firstLevelItem.items.forEach((secondLevelItem, index2)=>{
						if( fromPath === secondLevelItem.path ) fromFirstLevelIndex = index1
						if( toPath   === secondLevelItem.path ) toFirstLevelIndex = index1
					})
				})


				// secondLevelItems: find current and next index
				let fromSecondLevelIndex = undefined
				let toSecondLevelIndex   = undefined
				this.theSitemap.forEach((firstLevelItem, index1)=>{
					firstLevelItem.items.forEach((secondLevelItem, index2)=>{
						if( fromPath === secondLevelItem.path ) fromSecondLevelIndex = index2
						if( toPath   === secondLevelItem.path ) toSecondLevelIndex   = index2
					})
				})


				// if 404: find 404 and use their indexs as toId
				let error404FirstLevelIndex  = undefined
				let error404SecondLevelIndex = undefined
				if( this._.isUndefined(toFirstLevelIndex) ){
					this.theSitemap.forEach((firstLevelItem, index1)=>{
						firstLevelItem.items.forEach((secondLevelItem, index2)=>{
							if( secondLevelItem.is404 ){
								error404FirstLevelIndex = index1
								error404SecondLevelIndex = index2
							}
						})
					})

					toFirstLevelIndex = error404FirstLevelIndex
					toSecondLevelIndex = error404SecondLevelIndex
				}

				// set current and next id
				this.fromId = fromFirstLevelIndex+'_'+fromSecondLevelIndex
				this.toId   = toFirstLevelIndex+'_'+toSecondLevelIndex

				// firstLevelItems: set isVisible
				// das aktuelle und das kommende item sind sichtbar
				// aller anderen sind unsichtbar
				// damit wird sicher gestellt, dass immer maximal
				// 2 items sichtbar sein können.
				this.theSitemap.forEach((firstLevelItem, index1)=>{
					if( index1 == fromFirstLevelIndex ){
						firstLevelItem.isVisible = true
					}
					else if( index1 == toFirstLevelIndex){
						firstLevelItem.isVisible = true
					}
					else{
						firstLevelItem.isVisible = false
					}
				})


				// firstLevelItems: set position
				// alle items die sich vor dem neuem aktiven kommen, bekommen 'left'
				// alle die danach kommen bekommen 'right'
				// das item, dass den neuen index hat, bekommt 'center'
				this.theSitemap.forEach((firstLevelItem, index1)=>{
					if( index1 < toFirstLevelIndex ){
						firstLevelItem.position = 'left'
					}
					if( index1 == toFirstLevelIndex ){
						firstLevelItem.position = 'center'
					}
					if( index1 > toFirstLevelIndex ){
						firstLevelItem.position = 'right'
					}
				})


				// firstLevelItems: set isVisible
				this.theSitemap.forEach((firstLevelItem, index1)=>{
					firstLevelItem.items.forEach((secondLevelItem, index2)=>{
						if( secondLevelItem.id === this.fromId ) secondLevelItem.isVisible = true
						else if( secondLevelItem.id === this.toId ) secondLevelItem.isVisible = true
						else secondLevelItem.isVisible = false
					})
				})


				// secondLevelItems: set position
				this.theSitemap.forEach((firstLevelItem, index1)=>{
					if( index1 == toFirstLevelIndex ){
						firstLevelItem.items.forEach((secondLevelItem, index2)=>{
							// sonderfall: wenn von einem anderem firstLevelItem
							// gekommen wird der slide zum secondLevelItem beschleunigt
							if( fromFirstLevelIndex != toFirstLevelIndex ){
								secondLevelItem.quickTransition = true
							}
							else{
								secondLevelItem.quickTransition = false
							}

							if( index2 < toSecondLevelIndex ){
								secondLevelItem.position = 'top'
								this.$nextTick(()=>{
								})
							}
							if( index2 == toSecondLevelIndex ){
								secondLevelItem.position = 'center'
								this.$nextTick(()=>{
								})
							}
							if( index2 > toSecondLevelIndex ){
								secondLevelItem.position = 'bottom'
								this.$nextTick(()=>{
								})
							}
						})
					}
				})


				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• gotoView( fromPath, toPath )', toPath)
					console.log('                fromPath:', fromPath)
					console.log('                  toPath:', toPath)
					console.log('     fromFirstLevelIndex:', fromFirstLevelIndex)
					console.log('       toFirstLevelIndex:', toFirstLevelIndex)
					console.log('    fromSecondLevelIndex:', fromSecondLevelIndex)
					console.log('      toSecondLevelIndex:', toSecondLevelIndex)
					console.log(' error404FirstLevelIndex:', error404FirstLevelIndex)
					console.log('error404SecondLevelIndex:', error404SecondLevelIndex)
					console.groupEnd()
				}

				/*
				window.requestAnimationFrame(()=>{
					if( nextFirstLevelIndex ){
						this.theSitemap[currentFirstLevelIndex].isVisible = false
						this.theSitemap[nextFirstLevelIndex].isVisible = true
						//this.theSitemap[nextFirstLevelIndex].position = 'center'
						//console.log(this.theSitemap[nextFirstLevelIndex])
					}
				})
				*/
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AppBody { // vars
		--slideTransitionDuration : @transitions[bodySlideTransitionDuration];
	}
	.AppBody { // debug
		[showborders8] & {
			background-color: white;
			color: black;

			&__vertical::before { // current id as label
				position: absolute;
				top: 2rem; left: 1rem;
				content: attr( data-id );
				background-color: yellow;
				padding: 0.5rem;
				z-index: 2;
			}

			&__horizontal {
				background-color: fade(red, 15);

				&:nth-child(1){ background-color: fade(red, 75); }
				&:nth-child(2){ background-color: fade(red, 65); }
				&:nth-child(3){ background-color: fade(red, 55); }
				&:nth-child(4){ background-color: fade(red, 45); }
				&:nth-child(5){ background-color: fade(red, 35); }
				&:nth-child(6){ background-color: fade(red, 25); }
			}
			&__vertical {
				background-color: fade(green, 15);

				&:nth-child(1){ background-color: fade(green, 75); }
				&:nth-child(2){ background-color: fade(green, 65); }
				&:nth-child(3){ background-color: fade(green, 55); }
				&:nth-child(4){ background-color: fade(green, 45); }
				&:nth-child(5){ background-color: fade(green, 35); }
				&:nth-child(6){ background-color: fade(green, 25); }
			}

			&__debug {
				display: flex;
			}
		}
	}
	.AppBody { // layout
		position: absolute;
		top: 0; left: 0; width: 100%; height: 100%;
		overflow: hidden;

		//&--isAnimating { background-color: red; }

		&__horizontal {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			transition: transform var(--slideTransitionDuration) ease;
			will-change: transform;
			overflow: hidden;
			padding: 1rem; // just for dev

			&--position-left {
				transform: translateX(-100%) translateZ(0.1px);
				transform: translateX(-100%);
				pointer-events: none;
			}
			&--position-center {
				transform: translateX(0%) translateZ(0.1px);
				transform: translateX(0%);
				pointer-events: auto;
			}
			&--position-right {
				transform: translateX(100%) translateZ(0.1px);
				transform: translateX(100%);
				pointer-events: none;
			}
		}
		&__vertical {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			transition: transform var(--slideTransitionDuration) ease;
			will-change: transform;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			//padding: 1rem; // just for dev

			&--position-top {
				transform: translateY(-100%) translateZ(0.1px);
				//transform: translateY(-100%);
				pointer-events: none;
			}
			&--position-center {
				transform: translateY(0%) translateZ(0.1px);
				//transform: translateY(0%);
				pointer-events: auto;
			}
			&--position-bottom {
				transform: translateY(100%) translateZ(0.1px);
				//transform: translateY(100%);
				pointer-events: none;
			}
		}

		&__debug {
			position: fixed;
			left: 0; bottom: 0;
			padding: 0.5rem;
			z-index: 5;
			display: none;

			background-color: fade(yellow, 85);
			font-size: 12px;
			line-height: 1.25em;
			color: black;

			&Group { padding: 0 0.5rem; }
			&Group + &Group { border-left: 1px solid; }
		}
	}
	.AppBody { // scale down and blur transition if menu is open
		transition: all @transitions[projectOverlayDuration] ease;
		will-change: transform, opacity, filter;

		.App--isBodyBlured & {
			// welches probelm hat translateZ gleich gelöst?
			// ich habe es wieder rausgenommen, weil es im safari dazu geführt hat,
			// das man den AppBody bei den Fullscreen (!) Videos durchgesehen hat.
			//transform: scale(0.95) translateZ(0.1px);
			transform: scale(0.95);
			filter: blur(0.75em);
			opacity: 0.75;
			pointer-events: none;
		}
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
