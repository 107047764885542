<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<LayoutGrid></LayoutGrid>

	2019-06-08	init

-->

<template>
	<div class="LayoutGrid" :class="elmClasses" v-resize:throttle="onResize">
		<div class="LayoutGrid__debug">
			<pre name="$props">{{$props}}</pre>
			<pre name="oneColumnWidth • oneRowHeight">{{oneColumnWidth}} • {{oneRowHeight}}</pre>
			<!--
			<button @click="doMasonry">doMasonry</button>
			-->
			<button @click="setOneColumnWidth">setOneColumnWidth</button>
			<button @click="setOneRowHeight">setOneRowHeight</button>
		</div>
		<div class="LayoutGrid__inner" ref="grid" :style="innerElmStyles">
			<slot></slot>
			<div class="LayoutGrid__sizerHelper" ref="sizerHelper">&nbsp;</div>
			<!--
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import resize from 'vue-resize-directive'

	export default {
		name: 'LayoutGrid',
		components: {},
		directives: {
			resize,
		},
		mixins: [],
		props: {
			useDebugStyles: {
				type    : [String, Boolean],
				default : false,
			},
			rowHeight: {
				type    : [String],
				default : 'variable',
			},
			rowAspectRatio: {
				type    : [String],
				default : '16:9',
			},
			columns: {
				type    : [String, Number],
				default : 12,
			},
			gap: {
				type    : [String, Number],
				default : 0,
			},
		},
		data() {
			return {
				isAllowedMasonry : true,   // trash kann raus wenn XXXdoMasonry() gelöscht wird
				oneColumnWidth   : 0,
				oneRowHeight     : 0,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.useDebugStyles ) classes.push( this.$options.name + '--useDebugStyles' )

				classes.push( this.$options.name + '--rowHeight-' + this.rowHeight )

				return classes
			},
			innerElmStyles(){
				const styles = {
					gridTemplateColumns : 'repeat('+this.columns+', 1fr)',
					gridGap             : this.gap + 'px',
					paddingBottom       : this.gap + 'px',
					//gridAutoRows        : this.oneRowHeight + 'px',
				}

				if( this.rowHeight === 'fixed' ){
					styles.gridAutoRows = (this.oneRowHeight*1) + 'px'
				}

				return styles
			},
		},
		methods: {
			XXXhandleWindowResize( doLog = false ){ // TODO: muss nur ausgeführt werden, wenn rowHeight = 'fixed'
				const grid      = this.$refs.grid
				const gridWidth = window.getComputedStyle(grid).width

				if( doLog ){
					console.group( this.$options.name, ' • handleWindowResize()');
					console.log('grid :', grid)
					console.log('gridWidth :', gridWidth)
					console.groupEnd();
				}

				this.setOneColumnWidth()
				this.setOneRowHeight()
			},
			setOneColumnWidth( doLog = false ){
				const grid          = this.$refs.grid

				if( grid ){

					const gridColumns   = this.columns
					const gridWidth     = parseFloat( window.getComputedStyle(grid).width )
					const gridColumnGap = parseFloat( this.gap )
					const sizerElm      = this.$refs.sizerHelper

					sizerElm.style.display = 'block'

					const sizerElmWidth = sizerElm.offsetWidth

					if( doLog ){
						console.group( this.$options.name, ' • getOneColumnWidth()');
						console.log('grid:', grid)
						console.log('sizerElm:', sizerElm)
						console.log('gridWidth:', gridWidth)
						console.log('gridColumns:', gridColumns)
						console.log('gridColumnGap:', gridColumnGap)
						console.log('sizerElmWidth:', sizerElmWidth)
						console.groupEnd();
					}

					this.oneColumnWidth = sizerElmWidth

					sizerElm.style.display = ''

				}
			},
			setOneRowHeight( doLog = false ){
				const width  = this.oneColumnWidth
				const rowAspectRatio = this.rowAspectRatio.split(':')
				const ratio  = parseFloat(rowAspectRatio[0]) / parseFloat(rowAspectRatio[1])
				const height = width / ratio

				if( doLog ){
					console.group( this.$options.name, ' • setOneRowHeight()');
					console.log('width:', width)
					console.log('height:', height)
					console.groupEnd();
				}

				this.oneRowHeight = height
			},
			XXXdoMasonry( doLog = false ){ // kann raus, wird komplett via rows + columns im backend gelöst
				if( this.isAllowedMasonry ){
					const grid      = this.$refs.grid //el.querySelector("grid--masonry")[0];
					const allItems  = grid.children;
					const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
					const rowGap    = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));

					if( doLog ){
						console.group( this.$options.name, ' • doMasonry()');
						console.log('     this :', this);
						console.log('     grid :', grid);
						console.log(' allItems :', allItems);
						console.log('rowHeight :', rowHeight);
						console.log('   rowGap :', rowGap);
						console.groupEnd();
					}

					const resizeGridItem = (item)=>{
						// remove previous styling to get the correct boundingRect
						item.style.removeProperty('grid-row-end');

						const child        = item.children[0]
						const innerHeight  = child ? child.getBoundingClientRect().height : 0
						const rowSpan      = Math.ceil((innerHeight+rowGap)/(rowHeight+rowGap))
						const rowSpanStr   = "span " + rowSpan
						//const rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));

						if( doLog ){
							console.log(' -----------')
							console.log('        item:', item)
							console.log(' innerHeight:', innerHeight)
							console.log('  rowSpanStr:', rowSpanStr)
						}

						item.style.gridRowEnd = rowSpanStr;
					}
					const resizeAllGridItems = function() {
						for( let x=0;x<allItems.length;x++ ){
							resizeGridItem( allItems[x] )
						}
					}

					//window.onload = resizeAllGridItems();
					//window.addEventListener("resize", resizeAllGridItems);
					resizeAllGridItems()

				}
			},
			onResize( elm, doLog = false ){
				const elmWidth = window.getComputedStyle( elm ).width

				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • onResize()')
					console.log('elm:', elm)
					console.log('elmWidth:', elmWidth)
					console.groupEnd()
				}

				this.setOneColumnWidth()
				this.setOneRowHeight()
			},
		},
		created(){
			//window.addEventListener('resize', this.handleWindowResize);
		},
		mounted(){
			this.setOneColumnWidth()
			this.setOneRowHeight()
			//this.doMasonry()
		},
		destroyed(){
   			//window.removeEventListener('resize', this.handleWindowResize);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.LayoutGrid { // debug styling
		[showborders8] &,
		&--useDebugStyles {
			background-color: lighten( black, 50 );
			padding: 1em;
		}
		[showborders8] &__debug,
		&--useDebugStyles &__debug {
			display: block;
		}
		[showborders8] & .GridCell,
		&--useDebugStyles .GridCell {
			background-color: white;
		}
		[showborders8] & .GridCell__aspectRatio,
		&--useDebugStyles .GridCell__aspectRatio {
			.label('GridCell__aspectRatio', fade(black, 35));
			background-color: fade( black, 25 );
		}
		[showborders8] & .GridCell__content,
		&--useDebugStyles .GridCell__content {
			.label('GridCell__content', fade(red, 35));
			&::after { bottom: 0; left: 0; right: initial; top: initial; }
			position: absolute;

			background-color: fade( red, 35 );
		}
		[showborders8] & .GridCell__debug,
		&--useDebugStyles .GridCell__debug {
			display: block;
		}

		[showborders8] & .MediaBox,
		&--useDebugStyles .MediaBox {
			.label('MediaBox', fade(cyan, 35));
			position: absolute;

			margin: 1.5em;
			border: 1px solid cyan;
		}

		/*
		[showborders3] &__textWrapper {
			& > * { background-color: fade( brown, 50 ); }
		}
		*/
	}

	.LayoutGrid {
		&__debug {
			position: relative;
			background-color: fade( yellow, 50 );
			padding: 0.5em;
			margin-bottom: 0.5em;
			display: none;
			font-size: 14px;
			line-height: 1.2em;

			pre {
				color: black;
				background-color: fade( white, 10 );
			}
		}

		&__inner {
			display: grid;

			& > * {
				min-height: 0;
				min-width: 0;
			}
		}

		&__sizerHelper {
			background-color: pink;
			grid-column: span 4;
			display: none;
		}
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
