import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			name: 'HomeView',
			pathToRegexpOptions: { strict: true },
			component: false,
		},
		{
			path: '/directors/:slug/',
			name: 'DirectorsView',
			pathToRegexpOptions: { strict: true },
			component: false,
		},
		{
			path: '/photographers/:slug/',
			name: 'PhotographersView',
			pathToRegexpOptions: { strict: true },
			component: false,
		},
		{
			path: '/:slug/',
			name: 'PageView',
			pathToRegexpOptions: { strict: true },
			component: false,
		},
	]
})
