<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<AppFooter></AppFooter>

	2021-05-31	init

-->

<template>
	<div class="AppFooter" :class="elmClasses">
		<div class="AppFooter__links richText">
			<template v-for="(link, i) in _.get(app.acfOptions, 'footerLinks', [])">
				<router-link class="AppFooter__link link" v-if="link.type=='internal'" :to="app.getLinkWithoutHostname( link.internalLink )" :key="i">{{link.title}}</router-link>
				<a class="AppFooter__link link" v-if="link.type=='external'" :href="link.externalUrl"  :key="i" :target="link.newTab ? '_blank' : false">{{link.title}}</a>
			</template>
		</div>

		<!--
		<pre>{{app.acfOptions}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'AppFooter',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				deep: true,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			handleScroll(){
				console.log('scrolled')
			}
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.AppFooter {
		&__link {
			display: inline-block;
			padding: 0.75em 0;
			& + & { margin-left: 1em; }
		}
		.routerLink--active {
			color: @swatches[textRed];
		}
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
