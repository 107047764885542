<!--  

	MhRouterViewWrapper
	======
	Wrapper for <router-view></router-view> handles: 
	✅ fade transitions on view change (for sync and async content)
	✅ show loading spinner if needed
	✅ restores scroll positions at browsers back/forward button navigation
	✅ restores scroll position after reload
	❌ check if it was a hashnav. and not just a clicked link.
	❌ set <meta title="" />
	❌ scroll to hash

	See readme.txt for implementation
		
	2021-06-14	improvement: added css vars for custom styling
	2021-02-04	init

-->

<template>
	<div class="MhRouterViewWrapper">
		<!--		
			<transition
			  v-on:before-enter="onTransitionEvent(beforeEnter)"
			  v-on:enter="onTransitionEvent(enter)"
			  v-on:after-enter="onTransitionEvent(afterEnter)"
			  v-on:enter-cancelled="enterCancelled"

			  v-on:before-leave="onTransitionEvent(beforeLeave)"
			  v-on:leave="onTransitionEvent(leave)"
			  v-on:after-leave="onTransitionEvent(afterLeave)"
			  v-on:leave-cancelled="leaveCancelled"
			>
		-->
		
		<!-- original router view -->
		<transition 
			name="fade" 
			XXXmode="out-in" 
			mode="in-out" 
			:duration="{ leave: 2500, enter: 2500 }"
			
			v-on:before-enter="onTransitionEvent($event, 'beforeEnter')"
			v-on:enter="onTransitionEvent($event, 'enter')"
			v-on:after-enter="onTransitionEvent($event, 'afterEnter')"
			
			v-on:before-leave="onTransitionEvent($event, 'beforeLeave')"
			v-on:leave="onTransitionEvent($event, 'leave')"
			v-on:after-leave="onTransitionEvent($event, 'afterLeave')"
			
			appear>
			<router-view class="MhRouterViewWrapper__body" :key="_key" />
		</transition>
		
		<!-- loading spinner -->
		<svg class="MhRouterViewWrapper__spinner" 
			 :class="{'MhRouterViewWrapper__spinner--isVisible' : spinner.isVisible }"
			 height="1em" width="1em" viewBox="0 0 44 44" 
			 xmlns="http://www.w3.org/2000/svg" 
			 vector-effect="non-scaling-stroke">
			 	<g fill="none" fill-rule="evenodd" transform="translate(3, 3)">
					<g transform="translate(1 1)" vector-effect="non-scaling-stroke">
						<circle stroke-opacity=".35" cx="18" cy="18" r="18" vector-effect="non-scaling-stroke"></circle>
						<path d="M36 18c0-9.94-8.06-18-18-18" vector-effect="non-scaling-stroke">
							<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"></animateTransform>
						</path>
					</g>
				</g>
		</svg>

		<!-- debug infos -->
		<div class="MhRouterViewWrapper__debug">
			spinner.isVisible: {{spinner.isVisible}} <br/>
			viewContent.isVisible: {{viewContent.isVisible}} <br/>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src 
	import { EventBus } from '@/event-bus.js'
	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'

	export default {
		name: 'MhRouterViewWrapper',
		components: {
			MhSpinner
		},
		props: {
			viewChanged  : {
				type     : [String, Boolean],
				default  : undefined,
				required : false,
			},			
			scrollContext  : { // 'window', 'elm', querySelector string
				type     : [String, Boolean],
				default  : 'window',
				required : false,
			},
		},			
		data() {
			return {
				viewContent : {
					isVisible : undefined,
				},
				spinner : {
					isVisible : undefined
				},
			}
		},
		watch: {},
		computed: {
			_key(){ // wenn sich dieser key ändert wird ein route change getriggert
				return this.viewChanged ? this.viewChanged : this.$route.path
			},
			bodyClasses(){
				let classes = []
				
				//if( this.isVisible.body ) classes.push('MhRouterViewWrapper__body--isVisible')
				
				return classes
			},
			spinnerClasses(){
				let classes = []
				
				//if( this.isVisible.spinner ) classes.push('MhRouterViewWrapper__spinner--isVisible')
				
				return classes
			},
			wasReloadNavigation(){
				// Um einen Reload von einem normalen Aufruf zu unterscheiden, 
				// wird die performance API verwendet. 
				
				// Siehe: https://stackoverflow.com/a/36444134/7899788
				/*
				const hasNavigationTimingAPISupport = window.performance ? true : false
				let wasReloadNavigation = false
				
				if( hasNavigationTimingAPISupport ){
					if( performance.navigation.type == performance.navigation.TYPE_RELOAD ){
						//console.log("This page is reloaded.")
						//console.log("urlPath:", urlPath)
						//console.log("Saved scrollY:", scrollY )
						
						wasReloadNavigation = true
					}
					else{
						//console.log("This page is not reloaded")
					}
				}
				*/
				
				// Siehe https://stackoverflow.com/a/53307588/7899788
				const pageAccessedByReload = (
					(window.performance.navigation && window.performance.navigation.type === 1) ||
					 window.performance
						.getEntriesByType('navigation')
						.map((nav) => nav.type)
						.includes('reload')
				);				
				
				return pageAccessedByReload //wasReloadNavigation
			},
		},
		methods: {
			onTransitionEvent( elm, name, doLog = false ){
				if( doLog ){
					console.groupCollapsed('%c' + 'MhRouterViewWrapper • onTransitionEvent', 'background-color: yellow', name)
					console.groupEnd()
				}
			},
			// Beim Initialisieren der App sollen alle eventuell gespeicherten
			// scroll positions im sessionStorage entfernt werden,  damit nicht beim
			// neuen Navigieren eine zuvor gesetzte scroll position restored wird.
			// scroll positions sind für den jeweiligen load der SPA gültig.
			// eine ausnahme bildet die zuerst aufgerufene url einer session,
			// diese wird nicht entfernt, da bei einem reload der seite die 
			// scroll position sehr wohl wieder hergestellt werden soll.
			removeSavedScrollPositionsInSessionStorage(){
				const sessionStorageKeys = Object.keys(sessionStorage)
				const currentPathKey     = this.wasReloadNavigation ? 'scrollY--' + window.location.pathname : ''
				const scrollPositionKeys = this._.filter( this._.clone(sessionStorageKeys), (k)=>{ return this._.startsWith(k, 'scrollY--') && k !== currentPathKey } )
				//console.log( sessionStorageKeys )
				//console.log( scrollPositionKeys )
				//console.log('currentPathKey:', currentPathKey)
				
				scrollPositionKeys.forEach((k)=>{
					sessionStorage.removeItem( k )
				})
			},
			// just for dev
			devScrollEventHandler( e, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.group( this.$options.name, '• scrollEventHandler()')										
					console.log('e:', e)
					console.groupEnd()
				}
			},
		},
		beforeCreate(){
			// Handle scrollRestoration manual, prevents browser from doing it
			if('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual'			
		},
		created(){},
		beforeMount(){},
		mounted( doLog = false ){
			EventBus.$on('MhRouterView--viewContentIsReady', (state, doLog = false )=>{
				
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• $on MhRouterView--viewContentIsReady', state)
					console.groupEnd()
				}
				
				this.viewContent.isVisible = state				
				this.spinner.isVisible     = !state
				
				/*
				if( this.viewContent.reloadScrollY && state ){
					window.scrollTo(0, this.viewContent.reloadScrollY)
				}
				*/
				
				//console.groupEnd()
			})
			
			//this.restoreScrollPositionOnReload()
			this.removeSavedScrollPositionsInSessionStorage()
			
			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( this.$options.name, '• mounted()')
				console.log('this:', this)
				console.log('viewChanged:', this.viewChanged)
				console.log('wasReloadNavigation:', this.wasReloadNavigation)
				console.log('scrollContext:', this.scrollContext)
				console.groupEnd()
			}			
			//const scrollElm = this.$el
			//scrollElm.addEventListener('scroll', this.devScrollEventHandler)
		},
		beforeDestroy(){},
		destroyed(){
			//const scrollElm = this.$el
			//scrollElm.removeEventListener('scroll', this.devScrollEventHandler)
		},
	}
</script>

<style lang="less">	
	//@import (reference) "../../less/vars.less";
	//@import (reference) "../../less/mixins.less";
	//@import (reference) "../../less/atoms.less";

	.MhRouterViewWrapper { // vars
		--contentTransitionDuration : 500ms;
		--contentTransitionDuration : 2500ms;
		--contentTransitionDelay    :   0ms; // wofür war das gleich nochmal gut?

		--spinnerHeight             :  3rem;
		--spinnerWidth              :  3rem;
		--spinnerTransitionDuration : 350ms;
		--spinnerColor              : red;
		--spinnerStrokeWidth        :   5px;
		--spinnerOpacity            :  0.35;
	}

	.MhRouterView { // transition between views
		//transition-duration: var(--contentTransitionDuration);
		//transition-property: opacity;
		//transition-timing-function: ease;
		transition: opacity var(--contentTransitionDuration) ease,
				    transform var(--contentTransitionDuration) ease;
		opacity: 0;
		
		&.fade-enter-active,
		&.fade-leave-active {
			//transition-duration: var(--contentTransitionDuration);
			//transition-property: opacity;
			//transition-timing-function: ease;
			transition: opacity var(--contentTransitionDuration) ease,
					    transform var(--contentTransitionDuration) ease;
		}
		&.fade-enter-active {
			transition-delay: var(--contentTransitionDelay) !important;
		}
		&.fade-enter,
		&.fade-leave-active {
			transform: translateX(-100%);
			//opacity: 0;
			transition-delay: var(--contentTransitionDelay) !important;
		}		
		
		&--showContent {
			transition-delay: 50ms; // wofür war das gleich da?
			opacity: 1;
		}
	}
	.MhRouterViewWrapper__debug { // debug elm
		display: none !important;
		
		position: fixed;
		bottom: 2em; left: 2em;
		padding: 0.5em;
		
		background-color: fade(green, 75);
	} 
	.MhRouterViewWrapper__spinner { // spinner position and appearance
		position: fixed;
		top: 50vh; left: 50vw;
		height: var(--spinnerHeight); width: var(--spinnerWidth);
		transform: translateX(-50%) translateY(-50%) scale(1);
		pointer-events: none;
		transition: all var(--spinnerTransitionDuration) ease-out;
		opacity: 0;
		
		circle { 
			stroke: var(--spinnerColor);
			stroke-opacity: 0.5; 
			stroke-opacity: var(--spinnerOpacity); 
			stroke-width: var(--spinnerStrokeWidth);
		}		
		path { 
			stroke: var(--spinnerColor);
			stroke-width: var(--spinnerStrokeWidth);
		}
		
		&--isVisible { 
			//background-color: fade(green, 50);
			
			// die einblendung des spinners wird etwas verzögert,
			// damit für fetches, die bereits im cache sind der 
			// spinner nicht unnötig für den bruchteil einer sekunde
			// angezeigt wird.
			transition-delay: 100ms;
			opacity: 0.75;
		}
	}
	
	/*
	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
	*/
</style>
