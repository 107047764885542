<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhVideo
			:videoObject="{}"
		></MhVideo>

	2021-07-13	improvement: added allowLoad prop with true on default to handle loading media on offcanvas views
	2021-05-30	modified for doity
	2019-10-05	init

-->

<template>
	<div class="MhVideo" :class="elmClasses" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
	<!-- <div class="MhVideo" :class="elmClasses" @mouseenter="mouseEnter" @mouseleave="mouseLeave"> -->
		<div class="MhVideo__inner" :style="innerStyles">
			<!--
			<pre>{{controls}}</pre>
			-->
			{{ store.count }}
			<video
				class="MhVideo__videoElm"
				:src="videoSrc"
				:controls="controls"
				:loop="loopData"
				:poster="videoPoster"
				muted="true"
				:autoplay="autoplay"
				XXautoplay="false"
				:preload="preload"
				playsinline
				ref="videoElm"
				:id="videoObject.id"
				:data-id="videoObject.id"
			></video>
			<!--
			-->
		</div>

		<!--
		<pre data-name="videoObject">{{videoObject}}</pre>
	 	-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'
	import { store } from '../../store/store.js'

	export default {
		name: 'MhVideo',
		components: {},
		mixins: [],
		props: {
			videoObject: {
				type    : [Object, Boolean],
				default : false,
			},
			autoplay: {
				type    : [String, Boolean],
				default : false,
			},
			controls: {
				type    : [String, Boolean],
				default : false,
			},
			poster: {
				type    : [String, Boolean],
				default : false,
			},
			preload: {
				type    : [Boolean],
				default : false,
			},
			loop: {
				type    : [String, Boolean],
				default : true,
			},
			muted: {
				type    : [String, Boolean],
				default : false,
			},
			playClipOnHover: {
				type    : [String, Boolean],
				default : true,
			},
			allowLoad: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				isPlaying : false,
				store,
				loopData: false,
			}
		},
		watch: {
			autoplay: {
				handler: function( to, from, doLog = false ) {
					if( doLog ){
						console.group( this.$options.name + ' • watcher • autoplay', from, to)
						console.groupEnd()
					}

					// if( this.$refs.videoElm && this.videoSrc ){
					// 	if( to ){
					// 		this.$refs.videoElm.play()
					// 		this.isPlaying = true
					// 	}
					// 	else{
					// 		this.$refs.videoElm.pause()
					// 		this.$refs.videoElm.currentTime = 0
					// 		this.isPlaying = false
					// 	}
					// }
					return false
				},
				immediate : true,
				deep: false,
			},
			store: {
				handler: function() {
					// const isVideoActive = this.store.activeVideos.includes(this.videoObject.id)
					if(!this.store.activeVideos.includes(this.store.currentlyActiveVideoId)) {
						this.store.currentlyActiveVideoId = this.store.activeVideos[ Math.floor(Math.random() * this.store.activeVideos.length) ]
					}
					if( this.store.currentlyActiveVideoId === this.videoObject.id ){
						if(!this.isPlaying && this.$refs.videoElm ){
							console.log('should play this.videoObject.title', this.videoObject.title);
							this.$refs.videoElm.play()
							this.isPlaying = true
							this.$refs.videoElm.addEventListener("ended", this.handleEnded);
						}
					} else {
						if(this.isPlaying && this.$refs.videoElm ){
							console.log('should pause this.videoObject.title', this.videoObject.title);
							this.$refs.videoElm.pause()
							this.$refs.videoElm.removeEventListener("ended", this.handleEnded);
							this.isPlaying = false
						}
					}
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				return classes
			},
			innerStyles(){
				const paddingBottom = this.videoObject ? ( this.videoObject.height / this.videoObject.width ) * 100 : 0;

				return {
					paddingBottom : paddingBottom + '%',
					//color : this.textColor,
				}
			},
			videoSrc(){
				let src = this.videoObject ? this.videoObject.url : ''

				if( !this.allowLoad ) src = ''

				//if( this.allowLoad ) console.log( this.$options.name, '• src:', src)

				return src
			},
			videoPoster(){
				const posterSrc = this.poster
				//console.log('2 posterSrc:', posterSrc)

				return posterSrc ? posterSrc : ''
			},
		},
		methods: {
			handleEnded(e) {
				console.log('handleEnded', e)
				const videoId = parseInt(e.target.id)
				if(this.store.activeVideos.includes(videoId)) {
					const index = this.store.activeVideos.indexOf(videoId)
					this.store.currentlyActiveVideoId = this.store.activeVideos[index + 1] || this.store.activeVideos[0]
				}
			},
			handleEndedHover(e) {
				console.log('handleEndedHover', e)
			},
			mouseEnter(e){
				//console.log( this.$options.name, '• mouseEnter()')
				this.$refs.videoElm.addEventListener("ended", this.handleEnded);

				if( this.playClipOnHover ){
					if( !this.autoplay && !this.isPlaying && this.$refs.videoElm && this.videoSrc ){
							this.$refs.videoElm.play()
							this.isPlaying = true
							// this.loopData = true
						//handle autplay in viewport logic
						if(this.store.currentlyActiveVideoId !== this.videoObject.id) {
							this.store.restoreActiveVideoId = this.store.currentlyActiveVideoId
							this.store.currentlyActiveVideoId = this.videoObject.id
						}
					}
				}
					
			},
			mouseLeave(e){
				//console.log( this.$options.name, '• mouseLeave()')
				this.$refs.videoElm.removeEventListener("ended", this.handleEnded);

				// autoplay video cant be stoppped by hover leave
				if( this.playClipOnHover ){
					if( !this.autoplay && this.$refs.videoElm && this.videoSrc ){
						if( this.store.restoreActiveVideoId ) {
							this.$refs.videoElm.pause()
							this.$refs.videoElm.currentTime = 0
							this.isPlaying = false
							this.loopData = false
							this.store.currentlyActiveVideoId = this.store.restoreActiveVideoId
							this.store.restoreActiveVideoId = null
						}
					}
				}
			},
		},
		mounted(){
			if( this.videoObject ){
				const videoElm = this.$refs.videoElm
			}

			EventBus.$emit('MhVideo mounted')
		},
	}
</script>

<style lang="less">
	/*
	@import "../../less/vars.less";
	@import "../../less/mixins.less";
	@import (reference) "../../less/atoms.less";
	*/

	.MhVideo {
		position: relative;

		&__inner {
			position: relative;
			width: 100%;
			//background-color: fade(black, 15); // nicer not-loaded-yet look
		}
		&__videoElm {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			//pointer-events: none;

			&:focus {
				outline: none;
			}

		}

		video::-webkit-media-controls-play-button {
			display: none!important;
			-webkit-appearance: none;
		}


		// hide play button on iOS
		// https://stackoverflow.com/a/37553385
		video::-webkit-media-controls-start-playback-button {
			display: none!important;
			-webkit-appearance: none;
		}
	}
</style>
