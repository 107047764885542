<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<TextBox></TextBox>

	2019-06-08	init

-->

<template>
	<!--
	<div class="TextBox hasOverflow hasOverflow--hideScrollbars" :class="elmClasses">
	-->
	<div class="TextBox" :class="elmClasses">
		<div class="TextBox__content richText" v-html="text"></div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'TextBox',
		components: {},
		mixins: [],
		props: {
			text: {
				type    : [String, Boolean],
				default : "",
			},
			textVerticalAlign: {
				type    : [String],
				default : "bottom",
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--verticalAlign-' + this.textVerticalAlign )

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "../less/vars.less";
	@import (reference) "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.TextBox {
		position: relative;
		overflow: hidden;
		display: flex;
		//overflow-y: auto; // needed to make TextBox scrollable

		display: flex;
		flex-direction: column;

		&__content {
			width: 100%;
			max-height: 100%; // needed to make TextBox scrollable
		}

		// text muss hier leicht eingerückt sein,
		// damit er nicht zu nah an der linken schwarzen kante steht
		.ProjectOverlay--type-photo &__content > * {
			@media @mediaQueries[md] {
				padding-left: 0.25rem;
			}
		}

		&--verticalAlign-top {}
		&--verticalAlign-bottom {
			justify-content: flex-end;
		}
	}

	@media @mediaQueries[xs] {}
	@media @mediaQueries[sm] {}
	@media @mediaQueries[md] {}
	@media @mediaQueries[dt] {}
	@media @mediaQueries[lg] {}
	@media @mediaQueries[xl] {}
</style>
